<template xmlns="http://www.w3.org/1999/html">
  <section id="electrical-wrapper">
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-4 max-w-3xl">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <div class="mx-auto max-w-3xl">
        <template v-if="model.step === 'services_electrical_start'">
          <Headline>Wo wohnen sie?</Headline>
          <IconsOptionsSelection
            :options="houseTypes"
            v-model="model.base.type"
            cols="md:grid-cols-2"
          />
          <SingleNavigation
            v-if="
              (model.services.electrical.security_box.enabled ||
                model.services.electrical.data_cabinet.enabled) &&
              model.base.type === 'house'
            "
            step="services_electrical_security_box"
            prev="services_electrical_start"
            v-model="model"
            :disabled="!model.base.type"
            :fixed="true"
          />
          <SingleNavigation
            v-else
            step="services_electrical_security_box"
            prev="services_electrical_start"
            v-model="model"
            :disabled="!model.base.type"
            :fixed="true"
          />
        </template>
        <template v-if="model.step === 'services_electrical_security_box'">
          <Headline>Soll ein neuer Sicherungskasten verbaut werden?</Headline>
          <IconsOptionsSelection
            :options="security_box"
            v-model="selectedElectricalOptionComputed"
            cols="md:grid-cols-3"
          />
          <SingleNavigation
            v-if="
              (model.services.electrical.security_box.enabled ||
                model.services.electrical.data_cabinet.enabled) &&
              model.base.type === 'house'
            "
            step="services_electrical_heatPump"
            prev="services_electrical_start"
            v-model="model"
            :disabled="!selectedElectricalOptionComputed"
            :fixed="true"
          />
          <SingleNavigation
            v-else
            step="services_electrical_wiring"
            prev="services_electrical_start"
            v-model="model"
            :disabled="!selectedElectricalOptionComputed"
            :fixed="true"
          />
        </template>

        <template v-else-if="model.step === 'services_electrical_heatPump'">
          <Headline> Ist eine Wärmepumpe vorhanden oder soll eine verbaut werden?</Headline>
          <IconsOptionsSelection
            :options="yesNoOptions"
            v-model="model.services.electrical.heat_pump.enabled"
            cols="md:grid-cols-2"
          />
          <SingleNavigation
            step="services_electrical_wiring"
            prev="services_electrical_security_box"
            v-model="model"
            :fixed="true"
            :disabled="model.services.electrical.heat_pump.enabled === null"
          />
        </template>

        <template v-if="model.step === 'services_electrical_wiring'">
          <Headline>Sollen die elektrischen Leitungen erneuert werden?</Headline>
          <IconsOptionsSelection
            name="house_type"
            :options="yesNoOptions"
            cols="md:grid-cols-2"
            v-model="model.services.electrical.electrical_wiring.enabled"
          />
          <SingleNavigation
            :step="stepAfterElectricalWiring"
            :prev="stepBeforeElectricalWiring"
            v-model="model"
            :fixed="true"
            :disabled="model.services.electrical.electrical_wiring.enabled === null"
          />
        </template>

        <template v-if="model.step === 'services_electrical_rooms'">
          <Headline>In welchen Zimmern sollen neue Leitungen verlegt werden?</Headline>
          <IconsOptionsSelection
            :options="roomTypes"
            cols="md:grid-cols-3"
            v-model="selectedRoomTypes"
            @update:modelValue="updateRooms"
            multiple
          >
            <template #option-slot="{ option, isActive }">
              <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
                <span class="hidden font-semibold sm:block">Anzahl:</span>
                <span class="font-semibold sm:hidden">Anz.</span>
                <CounterInput
                  v-model="roomAmounts[option.value]"
                  @update:modelValue="handleCounter($event, option.value)"
                />
              </div>
            </template>
          </IconsOptionsSelection>
          <SingleNavigation
            :step="getNextStep"
            prev="services_electrical_wiring"
            v-model="model"
            :disabled="!hasSelectedRooms"
            :fixed="true"
          />
        </template>

        <!-- Dynamic Room Details Steps -->
        <template v-for="(roomId, index) in model.services.electrical.roomIds" :key="roomId">
          <template v-if="model.step === `services_electrical_size_${roomId}`">
            <Headline>
              Wie groß ist der folgende Raum:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?
            </Headline>
            <Range
              v-model="room(roomId).areas.floor"
              @update:modelValue="roomSize(roomId)"
              :max="100"
            />
            <SingleNavigation
              :step="getNextRoomStep(index, sortedRooms)"
              :prev="getPreviousStep(index, sortedRooms)"
              v-model="model"
              :fixed="true"
              :disabled="!room(roomId).areas.floor"
            />
          </template>
        </template>

        <template v-if="model.step === 'services_electrical_quality'">
          <Headline>Welche Qualität sollen die neuen Schalter und Steckdosen haben?</Headline>
          <IconsOptionsSelection
            name="house_type"
            :options="quality"
            cols="md:grid-cols-3"
            v-model="qualityComputed"
          />
          <SingleNavigation
            step="contactForm"
            :fixed="true"
            :prev="
              model.services.electrical.roomIds.length > 0
                ? `services_electrical_size_${model.services.electrical.roomIds[model.services.electrical.roomIds.length - 1]}`
                : 'services_electrical_wiring'
            "
            v-model="model"
            :disabled="!qualityComputed"
          />
        </template>
        <NoTotalMessage
          :model="model"
          :totals="totals"
          v-if="model.step === 'contactForm' && !initIsCurrentStepInTotals"
          :isCurrentStepInTotals="initIsCurrentStepInTotals"
        />

        <ContactPanel
          v-model="model"
          :totals="totals"
          :submitted="submitted"
          :submitting="submitting"
          :response="responseObject"
          :loading="loading"
          :single="true"
          v-if="
            (model.step === 'contact' ||
              model.step === 'contractor' ||
              model.step === 'contactForm') &&
            initIsCurrentStepInTotals
          "
        />
      </div>
    </form>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { extendModelByParams } from '@/utils/gclid'
import { security_box, roomTypes, quality, yesNoOptions } from '@/data/options/v2/electrical.js'
import { houseTypes } from '@/data/options/v2/base.js'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import {
  getRoom,
  getRoomCount,
  getRoomNameWithIndex,
  getSortedIndex,
  handleCounterUpdate,
  updateRoomCount,
  updateRoomSize,
  updateSelectedRooms
} from '@/utils/roomManagementUtils.js'
import { isCurrentStepInTotals } from '@/utils/steps.js'
import NoTotalMessage from '@/components/Structure/Singles/NoTotalMessage.vue'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = [
    'services_electrical_start',
    'services_electrical_security_box',
    'services_electrical_heatPump',
    'services_electrical_wiring',
    'services_electrical_rooms'
  ]

  const roomSteps = ['services_electrical_size']

  const postRoomSteps = ['services_electrical_quality', 'contactForm']

  // Generate steps for each room
  const roomSpecificSteps = baseSteps.concat(
    ...selectedRoomIds.value.flatMap((roomId) => roomSteps.map((step) => `${step}_${roomId}`))
  )

  // Combine all steps in the correct order
  const steps = [...baseSteps, ...roomSpecificSteps, ...postRoomSteps]

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'contactForm') {
    return 100
  }

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const autoNextStep = (step) => {
  model.value.step = step
}

const getNextStep = computed(() => {
  if (selectedRoomIds.value.length > 0) {
    return `services_electrical_size_${selectedRoomIds.value[0]}`
  }
  return 'services_electrical_start'
})

const getNextRoomStep = (currentIndex, sortedRooms) => {
  const nextIndex = currentIndex + 1
  if (nextIndex < selectedRoomIds.value.length) {
    return `services_electrical_size_${selectedRoomIds.value[nextIndex]}`
  }
  return 'services_electrical_quality'
}

const getPreviousStep = (currentIndex, sortedRooms) => {
  if (currentIndex > 0) {
    const prevIndex = currentIndex - 1
    return `services_electrical_size_${selectedRoomIds.value[prevIndex]}`
  }
  return 'services_electrical_rooms'
}

const selectedElectricalOption = ref(null)

const selectedElectricalOptionComputed = computed({
  get: () => {
    return selectedElectricalOption.value
  },
  set: (value) => {
    // Update the selected option
    selectedElectricalOption.value = value

    // Update model based on the selected option
    if (value === 'security_box') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = false
    } else if (value === 'data_cabinet') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = true
    } else if (value === null) {
      model.value.services.electrical.security_box.enabled = false
      model.value.services.electrical.data_cabinet.enabled = false
    }
  }
})

const qualityComputed = computed({
  get: () => {
    if (model.value.services.electrical.switch_programm.quality) {
      return model.value.services.electrical.switch_programm.quality
    }
    return ''
  },
  set: (value) => {
    model.value.services.electrical.switch_programm.quality = value
  }
})
const responseObject = ref()
const submitting = ref(false)
const submitted = ref(false)
const loading = ref(false)

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  const formModel = extendModelByParams(model.value)

  try {
    const response = await fetch(API_URL + '/api/forms/sc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formModel)
    })

    if (!response.ok) {
      console.error('Error submitting form:', response)
      const errorData = await response.json()
      responseObject.value = {
        error: true,
        message: errorData.message || 'An error occurred'
      }
    } else {
      const result = await response.json()
      const { data } = result
      responseObject.value = {
        data,
        message: 'Form successfully submitted!',
        error: false
      }

      track.fast('Lead_electrical', data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'electrical',
              content_name: 'electrical'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          external_id: data.id
        }
      )
      track.tmPurchase('electrical', data.volume / 100, 'EUR', data.id, model.value.contact)
    }

    // Mark as submitted
    submitted.value = true
  } catch (error) {
    // Catch-block: set error
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error
  } finally {
    submitting.value = false
  }
}

const stepAfterElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    return model.value.services.electrical.electrical_wiring.enabled && selectedElectricalOption
      ? 'services_electrical_rooms'
      : 'contactForm'
  } else if (model.value.base.type === 'apartment') {
    return model.value.services.electrical.electrical_wiring.enabled
      ? 'services_electrical_rooms'
      : 'contactForm'
  }
  return 'contactForm'
})

const stepBeforeElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    if (
      model.value.services.electrical.data_cabinet.enabled ||
      model.value.services.electrical.security_box.enabled
    ) {
      return 'services_electrical_heatPump'
    } else {
      return 'services_electrical_security_box'
    }
  }
  return 'services_electrical_security_box'
})

const selectedRoomIds = computed(() => {
  return model.value.services.electrical.roomIds || []
})

const selectedRoomTypes = computed({
  get: () => {
    return Array.from(
      new Set(
        selectedRoomIds.value
          .map((id) => model.value.rooms.find((r) => r.id === id)?.type)
          .filter(Boolean)
      )
    )
  },
  set: (newTypes) => {
    updateRooms(newTypes)
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  floor: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

const sortedRooms = computed(() => {
  return selectedRoomIds.value
    .map((id) => model.value.rooms.find((r) => r.id === id))
    .filter(Boolean)
    .sort((a, b) => {
      if (a.type < b.type) return -1
      if (a.type > b.type) return 1
      return 0
    })
})

const getFormattedRoomName = (label, overallIndex) => {
  return getRoomNameWithIndex(label, overallIndex, model, 'services.electrical', roomTypes)
}

const getRoomSortedIndex = (room) => {
  return getSortedIndex(room, model, 'services.electrical')
}

const updateRooms = (newTypes) => {
  updateSelectedRooms(model, 'services.electrical', newTypes, roomTypes)
  // Update room amounts
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = roomCount(type.value)
  })
}

const handleCounter = (value, roomType) => {
  handleCounterUpdate(model, 'services.electrical', roomAmounts, roomType, value, roomTypes)
}

const roomCount = (roomType) => {
  return getRoomCount(model, 'services.electrical', roomType)
}

const room = (roomId) => {
  return getRoom(model, roomId)
}

const roomSize = (roomId) => {
  updateRoomSize(model, roomId)
}

const hasSelectedRooms = computed(() => {
  return selectedRoomIds.value.length > 0
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRoomCount(model, 'services.electrical', roomType, newAmounts[roomType], roomTypes)
    })
  },
  { deep: true }
)

watch(
  () => model.value.rooms,
  (newRooms) => {
    //add new rooms to roomConfigs
    newRooms.forEach((room) => {
      if (!model.value.services.electrical.electrical_wiring.rooms.includes(room.id)) {
        model.value.services.electrical.electrical_wiring.rooms.push(room.id)
      }
    })

    // Remove extra rooms from roomConfigs
    model.value.services.electrical.electrical_wiring.rooms =
      model.value.services.electrical.electrical_wiring.rooms.filter((roomId) =>
        newRooms.some((room) => room.id === roomId)
      )
  },
  { deep: true }
)

watch(
  () => model.value.services.electrical.electrical_wiring.enabled,
  (newValue) => {
    if (newValue) {
      model.value.services.electrical.switch_programm.enabled = newValue
    }
  }
)

const initIsCurrentStepInTotals = computed(() => {
  if (model.value.step === 'contactForm') {
    return isCurrentStepInTotals(model, props.totals, 'electrical')
  }
})

watch(
  () => model.value.step,
  () => {
    if (model.value.step === 'contactForm') {
      initIsCurrentStepInTotals.value = isCurrentStepInTotals(model, props.totals, 'electrical')
      console.log('initIsCurrentStepInTotals', initIsCurrentStepInTotals.value)
    }
  }
)

onMounted(() => {
  // Initialize roomIds array if it doesn't exist
  if (!model.value.services.electrical.roomIds) {
    model.value.services.electrical.roomIds = []
  }

  // Initialize room amounts based on selected room IDs
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = getRoomCount(model, 'services.electrical', type.value)
  })

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'electric',
        content_name: 'electric'
      }
    ]
  })
  track.tmViewItem('electric')

  model.value.setup = true
  model.value.step = 'services_electrical_start'
  model.value.services.electrical.enabled = true
})
</script>

<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
