<template>
  <section>
    <form @submit.prevent="submitHandler" id="form" class="">
      <div class="mx-auto">
        <Headline>{{ headlineText }}</Headline>
      </div>
      <div class="mx-auto mb-4 max-w-3xl">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <div class="mx-auto mt-sm flex max-w-3xl flex-col gap-md">
        <template v-if="model.step === 'services_wallsCeilings_start'">
          <Headline>Welche Zimmer sollen bearbeitet werden?</Headline>
          <IconsOptionsSelection
            :options="roomTypes"
            cols="md:grid-cols-3"
            v-model="selectedRoomTypes"
            @update:modelValue="updateRooms"
            multiple
          >
            <template #option-slot="{ option, isActive }">
              <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
                <span class="hidden font-semibold sm:block">Anzahl:</span>
                <span class="font-semibold sm:hidden">Anz.</span>
                <CounterInput
                  v-model="roomAmounts[option.value]"
                  @update:modelValue="handleCounter($event, option.value)"
                />
              </div>
            </template>
          </IconsOptionsSelection>
          <SingleNavigation :step="getNextStep" v-model="model" :disabled="!hasSelectedRooms" />
        </template>
        <template v-for="(roomId, index) in model.services.wallsCeilings.roomIds" :key="roomId">
          <template v-if="model.step === `services_wallsCeilings_size_${roomId}`">
            <Headline>
              Wie groß ist der folgende Raum:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?
            </Headline>
            <Range
              v-model="room(roomId).areas.floor"
              @update:modelValue="roomSize(roomId)"
              :max="100"
            />
            <SingleNavigation
              :step="`services_wallsCeilings_change_${roomId}`"
              :prev="
                index === 0
                  ? 'services_wallsCeilings_start'
                  : `services_wallsCeilings_removeOld_${model.services.wallsCeilings.roomIds[index - 1]}`
              "
              v-model="model"
              :disabled="!room(roomId).areas.floor"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_change_${roomId}`">
            <Headline
              >Möchten Sie Ihre Wände bearbeiten:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="yesNo"
              cols="md:grid-cols-2"
              v-model="changeComputed"
            />
            <SingleNavigation
              v-if="changeComputed"
              :step="`services_wallsCeilings_change_type_${roomId}`"
              :prev="`services_wallsCeilings_size_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.change.enabled === null"
            />
            <SingleNavigation
              v-else
              :step="`services_wallsCeilings_changeCeiling_${roomId}`"
              :prev="`services_wallsCeilings_size_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.change.enabled === null"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_change_type_${roomId}`">
            <Headline
              >Wie sollen die Wände bearbeitet werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="wallsOptions"
              cols="md:grid-cols-2"
              v-model="changeTypeComputed"
            />
            <SingleNavigation
              :step="`services_wallsCeilings_change_quality_${roomId}`"
              :prev="`services_wallsCeilings_change_${roomId}`"
              v-model="model"
              :disabled="!changeTypeComputed"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_change_quality_${roomId}`">
            <Headline
              >In welcher Qualität wünschen Sie sich die neue/n Farbe/Fliesen:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="qualities"
              cols="md:grid-cols-3"
              v-model="model.services.wallsCeilings.change.quality"
            />
            <SingleNavigation
              :step="`services_wallsCeilings_changeCeiling_${roomId}`"
              :prev="`services_wallsCeilings_change_type_${roomId}`"
              v-model="model"
              :disabled="!changeComputed || !model.services.wallsCeilings.change.quality"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_changeCeiling_${roomId}`">
            <Headline
              >Sollen die Decken bearbeitet werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="yesNo"
              cols="md:grid-cols-2"
              v-model="changeCeilingComputed"
            />
            <SingleNavigation
              v-if="changeComputed && changeCeilingComputed"
              :step="`services_wallsCeilings_changeCeiling_type_${roomId}`"
              :prev="`services_wallsCeilings_change_type_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.changeCeiling.enabled === null"
            />
            <SingleNavigation
              v-else-if="!changeComputed && changeCeilingComputed"
              :step="`services_wallsCeilings_changeCeiling_type_${roomId}`"
              :prev="`services_wallsCeilings_change_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.changeCeiling.enabled === null"
            />
            <SingleNavigation
              v-else-if="changeComputed && !changeCeilingComputed"
              :step="`services_wallsCeilings_removeOld_${roomId}`"
              :prev="`services_wallsCeilings_change_quality_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.changeCeiling.enabled === null"
            />
            <SingleNavigation
              v-else-if="!changeComputed && !changeCeilingComputed"
              :step="`services_wallsCeilings_removeOld_${roomId}`"
              :prev="`services_wallsCeilings_change_${roomId}`"
              v-model="model"
              :disabled="model.services.wallsCeilings.changeCeiling.enabled === null"
            />
          </template>
          <template v-if="model.step === `services_wallsCeilings_changeCeiling_type_${roomId}`">
            <Headline
              >Wie sollen die Decken bearbeitet werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="wallsCeilingsOptions"
              cols="md:grid-cols-3"
              v-model="changeCeilingType"
            />
            <SingleNavigation
              :step="`services_wallsCeilings_changeCeiling_quality_${roomId}`"
              :prev="`services_wallsCeilings_changeCeiling_${roomId}`"
              v-model="model"
              :disabled="!changeCeilingType"
            />
          </template>
          <template v-if="model.step === `services_wallsCeilings_changeCeiling_quality_${roomId}`">
            <Headline
              >In welcher Qualität wünschen Sie sich die Farbe:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="qualities"
              cols="md:grid-cols-3"
              v-model="model.services.wallsCeilings.changeCeiling.quality"
            />
            <SingleNavigation
              v-if="changeCeilingType === 'all'"
              :step="`services_wallsCeilings_ceilingSpots_${roomId}`"
              :prev="`services_wallsCeilings_changeCeiling_type_${roomId}`"
              v-model="model"
              :disabled="!model.services.wallsCeilings.changeCeiling.quality"
            />
            <SingleNavigation
              v-else
              :step="`services_wallsCeilings_removeOld_${roomId}`"
              :prev="`services_wallsCeilings_changeCeiling_type_${roomId}`"
              v-model="model"
              :disabled="!model.services.wallsCeilings.changeCeiling.quality"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_ceilingSpots_${roomId}`">
            <Headline
              >Sollen Deckenspots verbaut werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection :options="yesNo" cols="md:grid-cols-2" v-model="ceilingSpots" />
            <SingleNavigation
              v-if="changeCeilingComputed"
              :step="`services_wallsCeilings_removeOld_${roomId}`"
              :prev="`services_wallsCeilings_changeCeiling_quality_${roomId}`"
              v-model="model"
            />
            <SingleNavigation
              v-else
              :step="`services_wallsCeilings_removeOld_${roomId}`"
              :prev="`services_wallsCeilings_changeCeiling_${roomId}`"
              v-model="model"
            />
          </template>

          <template v-if="model.step === `services_wallsCeilings_removeOld_${roomId}`">
            <Headline
              >Soll alte Tapete entfernt werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection :options="yesNo" cols="md:grid-cols-2" v-model="removeOld" />
            <SingleNavigation
              v-if="changeCeilingType === 'all'"
              :step="
                index === model.services.wallsCeilings.roomIds.length - 1
                  ? 'contactForm'
                  : `services_wallsCeilings_size_${model.services.wallsCeilings.roomIds[index + 1]}`
              "
              :prev="`services_wallsCeilings_ceilingSpots_${roomId}`"
              v-model="model"
              :disabled="removeOld === null"
            />
            <SingleNavigation
              v-else-if="changeCeilingComputed !== 'all' && changeCeilingComputed"
              :step="
                index === model.services.wallsCeilings.roomIds.length - 1
                  ? 'contactForm'
                  : `services_wallsCeilings_size_${model.services.wallsCeilings.roomIds[index + 1]}`
              "
              :prev="`services_wallsCeilings_change_quality_${roomId}`"
              v-model="model"
              :disabled="removeOld === null"
            />
            <SingleNavigation
              v-else-if="!changeCeilingComputed"
              :step="
                index === model.services.wallsCeilings.roomIds.length - 1
                  ? 'contactForm'
                  : `services_wallsCeilings_size_${model.services.wallsCeilings.roomIds[index + 1]}`
              "
              :prev="`services_wallsCeilings_changeCeiling_${roomId}`"
              v-model="model"
              :disabled="removeOld === null"
            />
          </template>
          <NoTotalMessage
            :model="model"
            :totals="totals"
            v-if="model.step === 'contactForm' && !initIsCurrentStepInTotals"
            :isCurrentStepInTotals="initIsCurrentStepInTotals"
          />
          <NoTotalMessage
            :model="model"
            :totals="totals"
            v-if="model.step === 'contactForm' && !initIsCurrentStepInTotals"
            :isCurrentStepInTotals="initIsCurrentStepInTotals"
          />

          <ContactPanel
            v-else
            :model-value="modelValue"
            :totals="totals"
            :submitted="submitted"
            :loading="loading"
            :response="responseObject"
            :submitting="submitting"
            v-slot="{ totals, goBack }"
            :single="true"
            v-if="
              (model.step === 'contact' ||
                model.step === 'contractor' ||
                model.step === 'contactForm') &&
              initIsCurrentStepInTotals
            "
          />
        </template>
      </div>
    </form>
  </section>
</template>

<script setup>
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import { computed, onMounted, ref, watch } from 'vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'

import {
  yesNo,
  wallsOptions,
  qualities,
  wallsCeilingsOptions,
  roomTypes
} from '@/data/options/v2/walls.js'
import Range from '@/components/Inputs/Singles/Range.vue'
import {
  getRoom,
  getRoomCount,
  getRoomNameWithIndex,
  getSortedIndex,
  handleCounterUpdate,
  updateRoomCount,
  updateRoomSize,
  updateSelectedRooms
} from '@/utils/roomManagementUtils.js'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import { extendModelByParams } from '@/utils/gclid'
import track from '@/utils/tracking.js'
import NoTotalMessage from '@/components/Structure/Singles/NoTotalMessage.vue'
import { isCurrentStepInTotals } from '@/utils/steps.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const responseObject = ref()
const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)

// Get selected room IDs for walls/ceilings service
const selectedRoomIds = computed(() => {
  return model.value.services.wallsCeilings.roomIds || []
})

const currentProgress = computed(() => {
  const baseSteps = ['services_wallsCeilings_start', 'contactForm']

  const roomSteps = [
    'services_wallsCeilings_size',
    'services_wallsCeilings_change',
    'services_wallsCeilings_change_type',
    'services_wallsCeilings_change_quality',
    'services_wallsCeilings_changeCeiling',
    'services_wallsCeilings_changeCeiling_type',
    'services_wallsCeilings_changeCeiling_quality',
    'services_wallsCeilings_ceilingSpots',
    'services_wallsCeilings_removeOld'
  ]

  // Generate steps for each selected room ID
  const steps = baseSteps.concat(
    ...selectedRoomIds.value.flatMap((roomId) => roomSteps.map((step) => `${step}_${roomId}`))
  )

  const currentStepIndex = steps.findIndex((step) => model.value.step === step)
  if (currentStepIndex === -1) return 0
  if (model.value.step === 'contactForm') return 100

  return (currentStepIndex / (steps.length - 1)) * 100
})

const getNextStep = computed(() => {
  if (selectedRoomIds.value.length > 0) {
    return `services_wallsCeilings_size_${selectedRoomIds.value[0]}`
  }
  return 'services_wallsCeilings_start'
})

const getFormattedRoomName = (label, overallIndex) => {
  return getRoomNameWithIndex(label, overallIndex, model, 'services.wallsCeilings', roomTypes)
}

const getRoomSortedIndex = (room) => {
  return getSortedIndex(room, model, 'services.wallsCeilings')
}

const updateRooms = (newTypes) => {
  updateSelectedRooms(model, 'services.wallsCeilings', newTypes, roomTypes)
  // Update room amounts
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = roomCount(type.value)
  })
}

const handleCounter = (value, roomType) => {
  handleCounterUpdate(model, 'services.wallsCeilings', roomAmounts, roomType, value, roomTypes)
}

const roomCount = (roomType) => {
  return getRoomCount(model, 'services.wallsCeilings', roomType)
}

const room = (roomId) => {
  return getRoom(model, roomId)
}

const roomSize = (roomId) => {
  updateRoomSize(model, roomId)
}

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.wallsCeilings.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.wallsCeilings.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

const changeComputed = computed({
  get: () => {
    if (
      !model.value.services.wallsCeilings.change.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] &&
      model.value.services.wallsCeilings.change.enabled === null
    ) {
      return null
    }
    return !!model.value.services.wallsCeilings.change.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.change.enabled = true
      model.value.services.wallsCeilings.change.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] = {
        type: ''
      }
    } else {
      model.value.services.wallsCeilings.change.enabled = false
      delete model.value.services.wallsCeilings.change.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ]
    }
  }
})

const changeTypeComputed = computed({
  get: () => {
    try {
      const wallsCeilings = model.value?.services?.wallsCeilings
      const change = wallsCeilings?.change
      const currentRoom = change?.currentRoom
      const roomConfigs = change?.roomConfigs

      if (currentRoom && roomConfigs && roomConfigs[currentRoom]) {
        return roomConfigs[currentRoom].type || ''
      }
      return ''
    } catch (error) {
      console.error('Error in changeTypeComputed getter:', error)
      return ''
    }
  },
  set: (v) => {
    try {
      const wallsCeilings = model.value?.services?.wallsCeilings
      const change = wallsCeilings?.change
      const currentRoom = change?.currentRoom
      const roomConfigs = change?.roomConfigs

      if (currentRoom && roomConfigs) {
        if (!roomConfigs[currentRoom]) {
          roomConfigs[currentRoom] = {}
        }
        roomConfigs[currentRoom].type = v
      } else {
        console.warn('Unable to set type: Invalid path to roomConfigs or currentRoom')
      }
    } catch (error) {
      console.error('Error in changeTypeComputed setter:', error)
    }
  }
})

const changeCeilingComputed = computed({
  get: () => {
    if (
      !model.value.services.wallsCeilings.changeCeiling.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] &&
      model.value.services.wallsCeilings.changeCeiling.enabled === null
    ) {
      return null
    }

    return !!model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.changeCeiling.enabled = true
      model.value.services.wallsCeilings.changeCeiling.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ] = {
        type: ''
      }
    } else {
      model.value.services.wallsCeilings.changeCeiling.enabled = false
      delete model.value.services.wallsCeilings.changeCeiling.roomConfigs[
        model.value.services.wallsCeilings.change.currentRoom
      ]
    }
  }
})

const changeCeilingType = computed({
  get: () =>
    model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ]?.type || '',
  set: (v) => {
    model.value.services.wallsCeilings.changeCeiling.roomConfigs[
      model.value.services.wallsCeilings.change.currentRoom
    ].type = v
  }
})

const ceilingSpots = computed({
  get: () => {
    return !!model.value.services.wallsCeilings.ceilingSpots.rooms.includes(
      model.value.services.wallsCeilings.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.ceilingSpots.enabled = true
      model.value.services.wallsCeilings.ceilingSpots.rooms.push(
        model.value.services.wallsCeilings.change.currentRoom
      )
    } else {
      model.value.services.wallsCeilings.ceilingSpots.rooms =
        model.value.services.wallsCeilings.ceilingSpots.rooms.filter(
          (room) => room !== model.value.services.wallsCeilings.change.currentRoom
        )
    }
  }
})

const removeOld = computed({
  get: () => {
    if (
      !model.value.services.wallsCeilings.removeOld.rooms[
        model.value.services.wallsCeilings.change.currentRoom
      ] &&
      model.value.services.wallsCeilings.removeOld.enabled === null
    ) {
      return null
    }

    return !!model.value.services.wallsCeilings.removeOld.rooms.includes(
      model.value.services.wallsCeilings.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.wallsCeilings.removeOld.enabled = true
      model.value.services.wallsCeilings.removeOld.rooms.push(
        model.value.services.wallsCeilings.change.currentRoom
      )
    } else {
      model.value.services.wallsCeilings.removeOld.enabled = false
      model.value.services.wallsCeilings.removeOld.rooms =
        model.value.services.wallsCeilings.removeOld.rooms.filter(
          (room) => room !== model.value.services.wallsCeilings.change.currentRoom
        )
    }
  }
})

const selectedRoomTypes = computed({
  get: () => {
    return Array.from(
      new Set(
        selectedRoomIds.value
          .map((id) => model.value.rooms.find((r) => r.id === id)?.type)
          .filter(Boolean)
      )
    )
  },
  set: (newTypes) => {
    updateRooms(newTypes)
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})
const initIsCurrentStepInTotals = computed(() => {
  if (model.value.step === 'contactForm') {
    return isCurrentStepInTotals(model, props.totals, 'wallsCeilings')
  }
})

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  const formModel = extendModelByParams(model.value)

  try {
    const response = await fetch(API_URL + '/api/forms/sc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formModel)
    })

    // If NOT ok → set error response
    if (!response.ok) {
      const errorData = await response.json()
      responseObject.value = {
        error: true,
        message: errorData.message || 'An error occurred'
      }
    } else {
      // If OK → set success response
      const result = await response.json()
      const { data } = result
      responseObject.value = {
        data,
        message: 'Form successfully submitted!',
        error: false
      }

      // The rest of your tracking
      track.fast('Lead_wallsCeilings', data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'wallsceilings',
              content_name: 'wallsceilings'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          external_id: data.id
        }
      )
      track.tmPurchase('wallsceilings', data.volume / 100, 'EUR', data.id, model.value.contact)
    }

    // Mark as submitted
    submitted.value = true
  } catch (error) {
    // Catch-block: set error
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error
  } finally {
    submitting.value = false
  }
}

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.wallsCeilings.change.currentRoom = roomId
    }
  }
)

const hasSelectedRooms = computed(() => {
  return selectedRoomIds.value.length > 0
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.wallsCeilings.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.wallsCeilings.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRoomCount(model, 'services.wallsCeilings', roomType, newAmounts[roomType], roomTypes)
    })
  },
  { deep: true }
)

onMounted(() => {
  model.value.base.type = 'house'
  model.value.services.wallsCeilings.enabled = true

  // Initialize roomIds array if it doesn't exist
  if (!model.value.services.wallsCeilings.roomIds) {
    model.value.services.wallsCeilings.roomIds = []
  }

  // Initialize room amounts based on selected room IDs
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = getRoomCount(model, 'services.wallsCeilings', type.value)
  })

  model.value.services.wallsCeilings.change.currentRoom = ''
  model.value.step = 'services_wallsCeilings_start'
})
</script>
