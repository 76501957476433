<template>
  <section id="heatpump-wrapper">
    <form @submit.prevent="submitHandler" id="form" class="p-3 md:p-10">
      <div class="mx-auto max-w-xl">
        <ProgressBar :progress="currentProgress" class="mb-8" />
      </div>
      <template v-if="model.step === 'start'">
        <Headline>Was für ein Haus haben Sie?</Headline>
        <IconsOptionsSelection :options="houseTypes" v-model="model.base.house_type" />
        <SingleNavigation
          step="heat"
          v-model="model"
          :disabled="!model.base.house_type"
          :fixed="true"
        />
      </template>
      <template v-else-if="model.step === 'heat'">
        <Headline>Wie heizen Sie?</Headline>
        <IconsOptionsSelection
          :options="heatings"
          v-model="model.additionals.heating_system.type"
        />
        <SingleNavigation
          step="size"
          prev="start"
          v-model="model"
          :disabled="!model.additionals.heating_system.type"
          :fixed="true"
        />
      </template>
      <template v-else-if="model.step === 'size'">
        <Headline>Wie groß ist die beheizbare Fläche?</Headline>

        <div class="mx-auto my-20 max-w-md space-y-5">
          <Range v-model="model.base.usage" :max="400" />
        </div>

        <SingleNavigation
          prev="heat"
          step="remove"
          v-model="model"
          :disabled="!model.base.usage"
          :fixed="true"
        />
      </template>
      <template v-else-if="model.step === 'remove'">
        <Headline>Soll eine alte Heizungsanlage entfernt werden?</Headline>
        <IconsOptionsSelection :options="removings" v-model="removingComputed" />
        <SingleNavigation
          prev="size"
          step="security"
          v-model="model"
          :disabled="!removingValue"
          :fixed="true"
        />
      </template>
      <template v-else-if="model.step === 'security'">
        <Headline>Soll der Sicherungskasten für die Wärmepumpe angepasst werden?</Headline>
        <IconsOptionsSelection
          :options="yesNo"
          cols="md:grid-cols-2"
          v-model="security_boxComputed"
        />
        <SingleNavigation
          prev="remove"
          step="contactForm"
          v-model="model"
          :disabled="security_boxComputed === null"
          :fixed="true"
        />
      </template>
      <ContactPanel
        v-else
        :model-value="modelValue"
        :totals="totals"
        :submitted="submitted"
        :loading="loading"
        :response="responseObject"
        :submitting="submitting"
        v-slot="{ totals, goBack }"
        :single="true"
        v-if="
          model.step === 'contact' || model.step === 'contractor' || model.step === 'contactForm'
        "
      />
    </form>
  </section>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { scrollToElement } from '@/utils/scroll'
import Range from '../Inputs/Singles/Range.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { extendModelByParams } from '@/utils/gclid'
import { houseTypes, yesNo, heatings, removings } from '@/data/options/heatPump.js'
const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {},
  totals: {}
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case 'start':
      return 0
    case 'heat':
      return 20
    case 'size':
      return 40
    case 'remove':
      return 60
    case 'security':
      return 80
    case 'contactForm':
      return 100
    default:
      return 0
  }
})

const removingValue = ref()
const removingComputed = computed({
  get: () => removingValue.value,
  set: (v) => {
    removingValue.value = v
    if (v !== 'no') {
      model.value.services.heating.heating_rebuild.type = v
    }
  }
})

const security_box = ref()
const security_boxComputed = computed({
  get: () => security_box.value,
  set: (v) => {
    security_box.value = v
    if (v !== 'yes') {
      model.value.services.electrical.security_box.enabled = false
    } else {
      model.value.services.electrical.security_box.enabled = v === 'yes'
    }
  }
})

const responseObject = ref()
const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)

const financialSupport = computed(() => {
  return Math.min(props.totals.total.value * 0.7, 21000)
})

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  const formModel = extendModelByParams(model.value)

  try {
    const response = await fetch(API_URL + '/api/forms/sc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formModel)
    })

    if (!response.ok) {
      const errorData = await response.json()
      responseObject.value = {
        error: true,
        message: errorData.message || 'An error occurred'
      }
    }

    const { data } = await response.json()

    submitted.value = true
    track.fast('Lead_Heatpump', data)
    track.yandex(97558574, 'reachGoal', 'form-click')
    track.tiktok(
      'SubmitForm',
      {
        contents: [
          {
            content_id: 'heatpump',
            content_name: 'heatpump'
          }
        ],
        value: data.volume / 100,
        currency: 'EUR'
      },
      {
        email: model.value.contact.email,
        external_id: data.id
      }
    )
    track.tmPurchase('heatpump', data.volume / 100, 'EUR', data.id, model.value.contact)

    return data
  } catch (error) {
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error // Re-throw the error for the caller to handle if needed
  } finally {
    submitting.value = false
  }
}

const nextStep = (step) => {
  if (window.innerWidth < 1024) {
    model.value.step = step
  }
}

onMounted(() => {
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'heatpump',
        content_name: 'heatpump'
      }
    ]
  })
  track.tmViewItem('heatpump')

  if (!model.value.setup_) {
    model.value.setup = true
    model.value.step = 'start'
    model.value.base.type = 'house'
    model.value.base.usage = 70
    model.value.services.electrical.enabled = true
    model.value.services.electrical.heat_pump.enabled = true
    model.value.services.heating.enabled = true
    model.value.services.heating.heating_system.enabled = true
    model.value.services.heating.heating_system.type = 'air-outer'
    model.value.services.heating.heating_rebuild.enabled = true
    model.value.additionals = {
      heating_system: {
        type: ''
      }
    }
  }
})

watch(
  () => model.value.step,
  (v) => {
    if (v === 'start') scrollToElement('#form')
  },
  {
    immediate: true,
    deep: true
  }
)
</script>
