<template>
  <section id="facade-wrapper">
    <form @submit.prevent="submitHandler" id="form" class="p-3 md:p-10">
      <div class="mx-auto mb-4 max-w-3xl">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <div class="mx-auto max-w-3xl">
        <template v-if="model.step === 'start'">
          <Headline>Was für ein Haus haben Sie?</Headline>
          <IconsOptionsSelection
            cols="md:grid-cols-2"
            :options="houseTypes"
            v-model="model.base.house_type"
          />
          <SingleNavigation
            step="facade"
            v-model="model"
            :disabled="!model.base.house_type"
            :fixed="true"
          />
        </template>
        <template v-else-if="model.step === 'facade'">
          <Headline>Welche Fassade wünschen Sie sich?</Headline>
          <IconsOptionsSelection
            :options="facades"
            cols="md:grid-cols-2"
            v-model="model.services.facade.renew.type"
          />

          <SingleNavigation
            step="size"
            prev="start"
            v-model="model"
            :disabled="!model.services.facade.renew.type"
            :fixed="true"
          />
        </template>
        <template v-else-if="model.step === 'size'">
          <Headline>Teilen Sie uns die Grunddaten zu Ihrem Haus mit</Headline>
          <div class="my-20 space-y-16">
            <div>
              <label class="mb-5 block text-center text-xl">
                Wieviel Quadratmeter Fassade haben Sie ca.?
              </label>
              <div class="mx-auto max-w-md">
                <Range v-model="model.services.facade.renew.size" :max="500" />
              </div>
            </div>
            <div>
              <label class="mb-5 block text-center text-xl">
                Um welches Baujahr handelt es sich?</label
              >
              <div class="mx-auto max-w-md">
                <Range
                  v-model="model.base.built"
                  suffix=" "
                  :formatter="(v) => v"
                  :min="1900"
                  :max="2024"
                />
              </div>
            </div>
          </div>

          <SingleNavigation
            prev="facade"
            step="contactForm"
            v-model="model"
            :disabled="!model.base.built || !model.services.facade.renew.size"
            :fixed="true"
          />
        </template>
      </div>
      <NoTotalMessage
        :model="model"
        :totals="totals"
        v-if="model.step === 'contactForm' && !initIsCurrentStepInTotals"
        :isCurrentStepInTotals="initIsCurrentStepInTotals"
      />

      <ContactPanel
        v-else
        :model-value="modelValue"
        :totals="totals"
        :submitted="submitted"
        :loading="loading"
        :response="responseObject"
        :submitting="submitting"
        v-slot="{ totals, goBack }"
        :single="true"
        v-if="
          (model.step === 'contact' ||
            model.step === 'contractor' ||
            model.step === 'contactForm') &&
          initIsCurrentStepInTotals
        "
      />
    </form>
  </section>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import { scrollToElement } from '@/utils/scroll'
import AnimatedNumber from '../Inputs/AnimatedNumber.vue'
import Range from '../Inputs/Singles/Range.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { extendModelByParams } from '@/utils/gclid'
import { houseTypes, facades } from '@/data/options/v2/facade'
import NoTotalMessage from '@/components/Structure/Singles/NoTotalMessage.vue'
import { isCurrentStepInTotals } from '@/utils/steps.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {},
  totals: {}
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  switch (model.value.step) {
    case 'start':
      return 0
    case 'facade':
      return 30
    case 'size':
      return 60
    case 'contactForm':
      return 100
    default:
      return 0
  }
})

const responseObject = ref()
const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)

const financialSupport = computed(() => {
  return Math.min(props.totals.total.value * 0.7, 21000)
})

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  const formModel = extendModelByParams(model.value)

  try {
    const response = await fetch(API_URL + '/api/forms/sc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formModel)
    })

    const { data } = await response.json()
    responseObject.value = {
      data,
      message: 'Form successfully submitted!',
      error: false
    }

    submitted.value = true
    track.fast('Lead_Facade', data)
    track.yandex(97558574, 'reachGoal', 'form-click')
    track.tiktok(
      'SubmitForm',
      {
        contents: [
          {
            content_id: 'facade',
            content_name: 'facade'
          }
        ],
        value: data.volume / 100,
        currency: 'EUR'
      },
      {
        email: model.value.contact.email,
        external_id: data.id
      }
    )
    track.tmPurchase('facade', data.volume / 100, 'EUR', data.id, model.value.contact)

    return data
  } catch (error) {
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error // Re-throw the error for the caller to handle if needed
  } finally {
    submitting.value = false
  }
}

const nextStep = (step) => {
  if (window.innerWidth < 1024) {
    model.value.step = step
  }
}

const initIsCurrentStepInTotals = computed(() => {
  if (model.value.step === 'contactForm') {
    return isCurrentStepInTotals(model, props.totals, 'facade')
  }
})

watch(
  () => model.value.step,
  () => {
    if (model.value.step === 'contactForm') {
      initIsCurrentStepInTotals.value = isCurrentStepInTotals(model, props.totals, 'facade')
      console.log('initIsCurrentStepInTotals', initIsCurrentStepInTotals.value)
    }
  }
)

onMounted(() => {
  console.log('total', props.totals)
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'facade',
        content_name: 'facade'
      }
    ]
  })
  track.tmViewItem('facade')

  if (!model.value.setup_) {
    model.value.setup = true
    model.value.step = 'start'
    model.value.base.type = 'house'
    model.value.base.built = '1973'
    model.value.base.usage = 1
    model.value.services.facade.enabled = true
    model.value.services.facade.renew.enabled = true
    model.value.services.facade.renew.size = 120
  }
})

watch(
  () => model.value.step,
  (v) => {
    if (v === 'start') scrollToElement('#form')
  },
  {
    immediate: true,
    deep: true
  }
)
</script>
