<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p :id="headlineText" class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <template v-if="model.step === 'services_electrical_start'">
        <Headline>Soll ein neuer Sicherungskasten verbaut werden?</Headline>
        <IconsOptionsSelection
          :options="security_box"
          v-model="selectedElectricalOptionComputed"
          cols="md:grid-cols-3"
        />
        <SingleNavigation
          v-if="selectedElectricalOption && model.base.type === 'house'"
          step="services_electrical_heatPump"
          prev="services_electrical_start"
          v-model="model"
          :disabled="!selectedElectricalOptionComputed"
        />
        <SingleNavigation
          v-else
          step="services_electrical_wiring"
          prev="services_electrical_start"
          v-model="model"
          :disabled="!selectedElectricalOptionComputed"
        />
      </template>

      <template
        v-else-if="
          model.step === 'services_electrical_heatPump' &&
          selectedElectricalOption &&
          model.base.type === 'house'
        "
      >
        <Headline> Ist eine Wärmepumpe vorhanden oder soll eine verbaut werden?</Headline>
        <IconsOptionsSelection
          :options="yesNoOptions"
          v-model="model.services.electrical.heat_pump.enabled"
          cols="md:grid-cols-2"
        />
        <SingleNavigation
          step="services_electrical_wiring"
          prev="services_electrical_start"
          v-model="model"
          :disabled="model.services.electrical.heat_pump.enabled === null"
        />
      </template>

      <template v-if="model.step === 'services_electrical_wiring'">
        <Headline>Sollen die elektrischen Leitungen erneuert werden?</Headline>
        <IconsOptionsSelection
          name="house_type"
          :options="yesNoOptions"
          cols="md:grid-cols-2"
          v-model="model.services.electrical.electrical_wiring.enabled"
        />
        <SingleNavigation
          :step="stepAfterElectricalWiring"
          :prev="stepBeforeElectricalWiring"
          v-model="model"
          :disabled="model.services.electrical.electrical_wiring.enabled === null"
        />
      </template>

      <template v-if="model.step === 'services_electrical_rooms'">
        <Headline>In welchen Zimmern sollen neue Leitungen verlegt werden?</Headline>
        <IconsOptionsSelection
          :options="roomTypes"
          cols="md:grid-cols-3"
          v-model="selectedRoomTypes"
          @update:modelValue="updateRooms"
          multiple
        >
          <template #option-slot="{ option, isActive }">
            <div v-if="isActive" class="mt-3 flex w-full flex-row items-center justify-between">
              <span class="hidden font-semibold sm:block">Anzahl:</span>
              <span class="font-semibold sm:hidden">Anz.</span>
              <CounterInput
                v-model="roomAmounts[option.value]"
                @update:modelValue="handleCounter($event, option.value)"
              />
            </div>
          </template>
        </IconsOptionsSelection>
        <SingleNavigation
          :step="getNextStep"
          prev="services_electrical_wiring"
          v-model="model"
          :disabled="!hasSelectedRooms"
        />
      </template>

      <!-- Dynamic Room Details Steps -->
      <template v-for="(roomId, index) in model.services.electrical.roomIds" :key="roomId">
        <template v-if="model.step === `services_electrical_size_${roomId}`">
          <Headline>
            Wie groß ist der folgende Raum:
            <span class="text-fancy">{{
              getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
            }}</span
            >?
          </Headline>
          <Range
            v-model="room(roomId).areas.floor"
            @update:modelValue="roomSize(roomId)"
            :max="100"
          />
          <SingleNavigation
            :step="getNextRoomStep(index, sortedRooms)"
            :prev="getPreviousStep(index, sortedRooms)"
            v-model="model"
            :disabled="!room(roomId).areas.floor"
          />
        </template>
      </template>

      <template v-if="model.step === 'services_electrical_quality'">
        <Headline>Welche Qualität sollen die neuen Schalter und Steckdosen haben?</Headline>
        <IconsOptionsSelection
          name="house_type"
          :options="quality"
          cols="md:grid-cols-3"
          v-model="qualityComputed"
        />
        <SingleNavigation
          step="services_electrical_final"
          :prev="
            model.services.electrical.roomIds.length > 0
              ? `services_electrical_size_${model.services.electrical.roomIds[model.services.electrical.roomIds.length - 1]}`
              : 'services_electrical_wiring'
          "
          v-model="model"
          :disabled="!qualityComputed"
        />
      </template>
    </form>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, toRaw, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { extendModelByParams } from '@/utils/gclid'
import { security_box, roomTypes, quality, yesNoOptions } from '@/data/options/v2/electrical.js'
import { ContactValidationSchema } from '@/utils/validators.js'
import {
  getRoom,
  getRoomCount,
  getRoomNameWithIndex,
  getSortedIndex,
  handleCounterUpdate,
  updateRoomCount,
  updateRoomSize,
  updateSelectedRooms
} from '@/utils/roomManagementUtils.js'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = [
    'services_electrical_start',
    'services_electrical_heatPump',
    'services_electrical_wiring',
    'services_electrical_rooms'
  ]

  const roomSteps = ['services_electrical_size']

  const postRoomSteps = ['services_electrical_quality', 'services_electrical_final']

  // Generate steps for each room
  const roomSpecificSteps = baseSteps.concat(
    ...selectedRoomIds.value.flatMap((roomId) => roomSteps.map((step) => `${step}_${roomId}`))
  )

  // Combine all steps in the correct order
  const steps = [...baseSteps, ...roomSpecificSteps, ...postRoomSteps]

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'services_electrical_final') {
    return 100
  }

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const getNextStep = computed(() => {
  if (selectedRoomIds.value.length > 0) {
    return `services_electrical_size_${selectedRoomIds.value[0]}`
  }
  return 'services_electrical_start'
})

const autoNextStep = (step) => {
  model.value.step = step
}

const getNextRoomStep = (currentIndex, sortedRooms) => {
  const nextIndex = currentIndex + 1
  if (nextIndex < selectedRoomIds.value.length) {
    return `services_electrical_size_${selectedRoomIds.value[nextIndex]}`
  }
  return 'services_electrical_quality'
}

const getPreviousStep = (currentIndex, sortedRooms) => {
  if (currentIndex > 0) {
    const prevIndex = currentIndex - 1
    return `services_electrical_size_${selectedRoomIds.value[prevIndex]}`
  }
  return 'services_electrical_rooms'
}

const getFormattedRoomName = (label, overallIndex) => {
  return getRoomNameWithIndex(label, overallIndex, model, 'services.electrical', roomTypes)
}

const getRoomSortedIndex = (room) => {
  return getSortedIndex(room, model, 'services.electrical')
}

const updateRooms = (newTypes) => {
  updateSelectedRooms(model, 'services.electrical', newTypes, roomTypes)
  // Update room amounts
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = roomCount(type.value)
  })
}

const handleCounter = (value, roomType) => {
  handleCounterUpdate(model, 'services.electrical', roomAmounts, roomType, value, roomTypes)
}

const roomCount = (roomType) => {
  return getRoomCount(model, 'services.electrical', roomType)
}

const room = (roomId) => {
  return getRoom(model, roomId)
}

const roomSize = (roomId) => {
  updateRoomSize(model, roomId)
}

const selectedElectricalOption = ref(null)

const selectedElectricalOptionComputed = computed({
  get: () => {
    const securityBoxEnabled = model.value.services.electrical.security_box.enabled
    const dataCabinetEnabled = model.value.services.electrical.data_cabinet.enabled

    if (securityBoxEnabled && !dataCabinetEnabled) {
      return 'security_box'
    } else if (securityBoxEnabled && dataCabinetEnabled) {
      return 'data_cabinet'
    } else if (securityBoxEnabled === false && dataCabinetEnabled === false) {
      return 'no' // Changed from false to 'no'
    } else if (securityBoxEnabled === null && dataCabinetEnabled === null) {
      return null
    }
  },
  set: (value) => {
    // Update the selected option
    selectedElectricalOption.value = value

    // Update model based on the selected option
    if (value === 'security_box') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = false
    } else if (value === 'data_cabinet') {
      model.value.services.electrical.security_box.enabled = true
      model.value.services.electrical.data_cabinet.enabled = true
    } else if (value === 'no') {
      model.value.services.electrical.security_box.enabled = false
      model.value.services.electrical.data_cabinet.enabled = false
    } else if (value === null) {
      model.value.services.electrical.security_box.enabled = null
      model.value.services.electrical.data_cabinet.enabled = null
    }
  }
})

const qualityComputed = computed({
  get: () => {
    if (model.value.services.electrical.switch_programm.quality) {
      return model.value.services.electrical.switch_programm.quality
    }
    return ''
  },
  set: (value) => {
    model.value.services.electrical.switch_programm.quality = value
  }
})

const submitting = ref(false)
const submitted = ref(false)

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(props.modelValue.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  const formModel = extendModelByParams(props.modelValue)

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(formModel)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true

      track.fast('Lead_Electrical', data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'electric',
              content_name: 'electric'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: model.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('electric', data.volume / 100, 'EUR', data.id, model.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

const stepAfterElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    return model.value.services.electrical.electrical_wiring.enabled && selectedElectricalOption
      ? 'services_electrical_rooms'
      : 'services_electrical_final'
  } else if (model.value.base.type === 'apartment') {
    return model.value.services.electrical.electrical_wiring.enabled
      ? 'services_electrical_rooms'
      : 'services_electrical_final'
  }
  return 'services_electrical_final'
})

const stepBeforeElectricalWiring = computed(() => {
  if (model.value.base.type === 'house') {
    if (
      model.value.services.electrical.data_cabinet.enabled ||
      model.value.services.electrical.security_box.enabled
    ) {
      return 'services_electrical_heatPump'
    } else {
      return 'services_electrical_start'
    }
  }
  return 'services_electrical_start'
})

const selectedRoomIds = computed(() => {
  return model.value.services.electrical.roomIds || []
})

const selectedRoomTypes = computed({
  get: () => {
    return Array.from(
      new Set(
        selectedRoomIds.value
          .map((id) => model.value.rooms.find((r) => r.id === id)?.type)
          .filter(Boolean)
      )
    )
  },
  set: (newTypes) => {
    updateRooms(newTypes)
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  floor: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

const sortedRooms = computed(() => {
  return selectedRoomIds.value
    .map((id) => model.value.rooms.find((r) => r.id === id))
    .filter(Boolean)
    .sort((a, b) => {
      if (a.type < b.type) return -1
      if (a.type > b.type) return 1
      return 0
    })
})

const hasSelectedRooms = computed(() => {
  return selectedRoomIds.value.length > 0
})

watch(
  roomAmounts,
  (newAmounts, oldAmounts) => {
    Object.keys(newAmounts).forEach((roomType) => {
      updateRoomCount(model, 'services.electrical', roomType, newAmounts[roomType], roomTypes)
    })
  },
  { deep: true }
)

watch(
  () => model.value.rooms,
  (newRooms) => {
    //add new rooms to roomConfigs
    newRooms.forEach((room) => {
      if (!model.value.services.electrical.electrical_wiring.rooms.includes(room.id)) {
        model.value.services.electrical.electrical_wiring.rooms.push(room.id)
      }
    })

    // Remove extra rooms from roomConfigs
    model.value.services.electrical.electrical_wiring.rooms =
      model.value.services.electrical.electrical_wiring.rooms.filter((roomId) =>
        newRooms.some((room) => room.id === roomId)
      )
  },
  { deep: true }
)

watch(
  () => model.value.services.electrical.electrical_wiring.enabled,
  (newValue) => {
    if (newValue) {
      model.value.services.electrical.switch_programm.enabled = newValue
    }
  }
)

onMounted(() => {
  // Initialize roomIds array if it doesn't exist
  if (!model.value.services.electrical.roomIds) {
    model.value.services.electrical.roomIds = []
  }

  // Initialize room amounts based on selected room IDs
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = getRoomCount(model, 'services.electrical', type.value)
  })

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'electric',
        content_name: 'electric'
      }
    ]
  })
  track.tmViewItem('electric')

  model.value.setup = true
  model.value.step = 'services_electrical_start'
  model.value.services.electrical.enabled = true
})
</script>

<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
