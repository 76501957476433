<template xmlns="http://www.w3.org/1999/html">
  <section>
    <form id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p :id="headlineText" class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-8">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <!-- Corrected from <tem> to <div> -->
      <template v-if="model.step === 'services_baths_start'">
        <div class="mb-20">
          <Headline>Wie viele Badezimmer/Gäste-WCs haben Sie?</Headline>
          <div class="mx-auto mt-14">
            <div class="mb-4 flex flex-row items-center justify-between">
              <span class="text-md font-semibold">Anzahl der Badezimmer/Gäste-WCs:</span>
              <CounterInput v-model="bathCreator" />
            </div>
          </div>
          <SingleNavigation :step="getNextStep" v-model="model" :disabled="!hasSelectedRooms" />
        </div>
      </template>

      <template v-for="(roomId, index) in model.services.baths.roomIds" :key="roomId">
        <template v-if="model.step === `services_baths_size_${roomId}`">
          <Headline>
            Wie groß ist der folgende Raum:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?
          </Headline>

          <div class="my-20 space-y-16">
            <Range v-model="model.rooms.find((r) => r.id === roomId).areas.floor" :max="80" />
          </div>
          <SingleNavigation
            :step="`services_baths_floor_${roomId}`"
            :prev="
              index === 0
                ? 'services_baths_start'
                : `services_baths_extras_${model.services.baths.roomIds[index - 1]}`
            "
            v-model="model"
            :disabled="!model.rooms.find((r) => r.id === roomId).areas.floor"
          />
        </template>

        <template v-if="model.step === `services_baths_floor_${roomId}`">
          <Headline
            >Soll der Boden gefliest werden:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="yesNo" v-model="floorComputed" cols="md:grid-cols-2" />
          <SingleNavigation
            v-if="floorComputed"
            :step="`services_baths_floor_quality_${roomId}`"
            :prev="`services_baths_size_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_walls_${roomId}`"
            :prev="`services_baths_size_${roomId}`"
            v-model="model"
            :disabled="floorComputed === null"
          />
        </template>
        <template v-if="model.step === `services_baths_floor_quality_${roomId}`">
          <Headline
            >In welcher Qualität wünschen Sie sich die neuen Fliesen:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="floors" v-model="floorValue" cols="md:grid-cols-3" />
          <SingleNavigation
            :step="`services_baths_walls_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
            :disabled="model.services.baths.change.roomConfigs[roomId].floor.quality === ''"
          />
        </template>

        <template v-if="model.step === `services_baths_walls_${roomId}`">
          <Headline
            >Sollen die Wände gefliest werden:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="yesNo" v-model="wallComputed" cols="md:grid-cols-2" />
          <SingleNavigation
            v-if="floorComputed & wallComputed"
            :step="`services_baths_walls_quality_${roomId}`"
            :prev="`services_baths_floor_quality_${roomId}`"
            v-model="model"
            :disabled="wallComputed === null"
          />
          <SingleNavigation
            v-else-if="!floorComputed & wallComputed"
            :step="`services_baths_walls_quality_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
            :disabled="wallComputed === null"
          />
          <SingleNavigation
            v-else-if="floorComputed & !wallComputed"
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_floor_quality_${roomId}`"
            v-model="model"
            :disabled="wallComputed === null"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_floor_${roomId}`"
            v-model="model"
            :disabled="wallComputed === null"
          />
        </template>

        <template v-if="model.step === `services_baths_walls_quality_${roomId}`">
          <Headline
            >In welcher Qualität wünschen Sie sich die neuen Fliesen:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection :options="walls" v-model="wallValue" cols="md:grid-cols-3" />
          <SingleNavigation
            :step="`services_baths_wall-range_${roomId}`"
            :prev="`services_baths_walls_${roomId}`"
            v-model="model"
            :disabled="model.services.baths.change.roomConfigs[roomId].walls.quality === ''"
          />
        </template>

        <template v-if="model.step === `services_baths_wall-range_${roomId}`">
          <Headline class="mt-10"
            >Wie viel Quadratmeter Wand sollen gefliest werden:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <Range
            v-model="wallRange"
            :max="100"
            :helper-text="`Empfohlene Wandfläche: ${recommendedWallRange}`"
          />
          <SingleNavigation
            :step="`services_baths_interior_${roomId}`"
            :prev="`services_baths_walls_quality_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_interior_${roomId}`">
          <Headline
            >Welche Ausstattung wünschen Sie sich:
            <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
            >?</Headline
          >
          <IconsOptionsSelection
            :options="interiors"
            v-model="interiorValue"
            multiple
            cols="md:grid-cols-5"
            mobileCold="grid-cols-1"
          >
            <template #option-slot="{ option, isActive }">
              <QualityOptionsSelection
                class="my-5 w-full"
                v-if="isActive"
                :options="qualities"
                :model-value="getQualityForType(option.value)"
                @update:modelValue="(v) => setQualityForType(option.value, v)"
              />
            </template>
          </IconsOptionsSelection>
          <SingleNavigation
            v-if="wallComputed"
            :step="`services_baths_extras_${roomId}`"
            :prev="`services_baths_wall-range_${roomId}`"
            v-model="model"
          />
          <SingleNavigation
            v-else
            :step="`services_baths_extras_${roomId}`"
            :prev="`services_baths_walls_${roomId}`"
            v-model="model"
          />
        </template>

        <template v-if="model.step === `services_baths_extras_${roomId}`">
          <div class="mb-20">
            <Headline>
              Welche zusätzlichen Wünsche haben Sie:
              <span class="text-fancy">{{ getRoomNameWithIndex(roomId) }}</span
              >?
            </Headline>
            <IconsOptionsSelection
              :options="extras"
              cols="md:grid-cols-2"
              v-model="extrasValue"
              multiple
            />
            <SingleNavigation
              :step="
                index === model.services.baths.roomIds.length - 1
                  ? 'services_baths_final'
                  : `services_baths_size_${model.services.baths.roomIds[index + 1]}`
              "
              :prev="`services_baths_interior_${roomId}`"
              v-model="model"
            />
          </div>
        </template>
      </template>
    </form>
  </section>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import createRoom from '@/utils/room'
import { useLocalStorage } from '@vueuse/core'
import QualityOptionsSelection from '@/components/Inputs/QualityOptionsSelection.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import { yesNo, interiors, floors, qualities } from '@/data/options/v2/baths.js'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import PartitionImage from '@public/images/singles/bath/x_partition.svg'
import TowelHeatingImage from '@public/images/singles/bath/x_towel_heating.svg'
import SpotsImage from '@public/images/singles/bath/x_spots.svg'
const API_URL = import.meta.env.VITE_API_URL
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = ['services_baths_start']

  const roomSteps = [
    'services_baths_size',
    'services_baths_floor',
    'services_baths_floor_quality',
    'services_baths_walls',
    'services_baths_walls_quality',
    'services_baths_wall-range',
    'services_baths_interior',
    'services_baths_extras'
  ]

  const postRoomSteps = ['services_baths_final']

  // Generate steps for each room
  const roomSpecificSteps = Object.keys(model.value.services.baths.change.roomConfigs).flatMap(
    (roomId) => roomSteps.map((step) => `${step}_${roomId}`)
  )

  // Combine all steps in the correct order
  const steps = [...baseSteps, ...roomSpecificSteps, ...postRoomSteps]

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (currentStepIndex === -1) return 0

  if (model.value.step === 'services_baths_final') {
    return 100
  }

  // Calculate progress percentage
  return Math.round((currentStepIndex / (steps.length - 1)) * 100)
})

const getRoomNameWithIndex = (roomId) => {
  // Check if the room exists in model.value.rooms
  const currentRoom = model.value.rooms.find((r) => r.id === roomId)
  if (!currentRoom) {
    console.error(`Room with id ${roomId} not found in model.value.rooms`)
    return ''
  }

  // Get all room IDs for baths service
  const bathRoomIds = model.value.services.baths.roomIds

  // Calculate total rooms
  const totalRooms = bathRoomIds.length

  // Find index of current room among all rooms in the baths service
  const roomIndex = bathRoomIds.findIndex((id) => id === roomId) + 1

  // Return the formatted room name
  return totalRooms > 1 ? `${currentRoom.name} ${roomIndex}/${totalRooms}` : currentRoom.name
}

const getNextStep = computed(() => {
  // Get array of room IDs from the baths service
  const bathRoomIds = model.value.services.baths.roomIds

  // Check if there are any room IDs
  if (bathRoomIds.length > 0) {
    // Get the first room ID
    const firstRoomId = bathRoomIds[0]
    return `services_baths_size_${firstRoomId}`
  }

  // If there are no room IDs, return to start
  return 'services_baths_start'
})

const bathAmount = ref(0)

const bathCreator = computed({
  get: () => {
    return model.value.services.baths.roomIds.length
  },
  set: (v) => {
    event.preventDefault()
    const currentLength = model.value.services.baths.roomIds.length

    if (v > currentLength) {
      // Add new rooms
      for (let i = currentLength; i < v; i++) {
        const room = createRoom('Badezimmer', 'bathroom', false, true)
        model.value.rooms.push(room)
        // Initialize the room config as an object property, not array element
        model.value.services.baths.change.roomConfigs[room.id] = {
          ...JSON.parse(JSON.stringify(roomConfig.value))
        }
        model.value.services.baths.roomIds.push(room.id)
      }
    } else if (v < currentLength) {
      // Remove excess rooms
      const roomIds = model.value.services.baths.roomIds
      for (let i = currentLength - 1; i >= v; i--) {
        const roomId = roomIds[i]
        // Delete the room config property
        delete model.value.services.baths.change.roomConfigs[roomId]
        model.value.rooms = model.value.rooms.filter((room) => room.id !== roomId)
        model.value.services.baths.roomIds = model.value.services.baths.roomIds.filter((id) =>
          model.value.rooms.map((room) => room.id).includes(id)
        )
      }
    }
    // Update bathAmount
    bathAmount.value = Object.keys(model.value.services.baths.change.roomConfigs).length
  }
})

const recommendedWallRange = computed(() => {
  const currentRoom = model.value.services.baths.change.currentRoom
  return model.value.rooms.find((r) => r.id === currentRoom).areas.floor
})

const wallRange = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom

    if (!model.value.services.baths.change.roomConfigs[currentRoom].walls.size) {
      return recommendedWallRange.value
    }

    return model.value.services.baths.change.roomConfigs[currentRoom].walls.size
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.size = v
  }
})

const floorComputed = computed({
  get: () => {
    if (model.value.services.baths.change.enabled === null) {
      return null
    }
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled
  },
  set: (v) => {
    if (v) {
      model.value.services.baths.change.enabled = true
      const currentRoom = model.value.services.baths.change.currentRoom
      model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled = v
    } else {
      const currentRoom = model.value.services.baths.change.currentRoom
      model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled = v
    }
  }
})

const floorValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled
      ? model.value.services.baths.change.roomConfigs[currentRoom].floor.quality
      : ''
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].floor.enabled = v ? true : false
    model.value.services.baths.change.roomConfigs[currentRoom].floor.quality = v
  }
})

const wallComputed = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled = v
    model.value.services.baths.change.roomConfigs[currentRoom].walls.size =
      recommendedWallRange.value
  }
})

const wallValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    return model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled
      ? model.value.services.baths.change.roomConfigs[currentRoom].walls.quality
      : ''
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    model.value.services.baths.change.roomConfigs[currentRoom].walls.enabled = v ? true : false
    model.value.services.baths.change.roomConfigs[currentRoom].walls.quality = v
  }
})

const walls = floors

const getQualityForType = (type) => {
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
  if (type === 'doublesink') {
    return roomConfig.sink.tub.quality
  }
  if (type === 'toilet') {
    return roomConfig.toilet.quality
  }

  return roomConfig[type].tub.quality
}

const setQualityForType = (type, quality) => {
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
  if (type === 'doublesink') {
    roomConfig.sink.tub.quality = quality
    roomConfig.sink.handle.quality = quality
    return
  }

  if (type === 'toilet') {
    roomConfig.toilet.quality = quality
    return
  }

  roomConfig[type].tub.quality = quality
  roomConfig[type].tub.quality = quality
}

const interiorValue = computed({
  get: () => {
    const values = []
    const currentRoom = model.value.services.baths.change.currentRoom
    const currentRoomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

    if (currentRoomConfig && currentRoomConfig.shower?.enabled) values.push('shower')
    if (currentRoomConfig && currentRoomConfig.bathtub?.enabled) values.push('bathtub')
    if (currentRoomConfig && currentRoomConfig.toilet?.enabled) values.push('toilet')
    if (
      currentRoomConfig &&
      currentRoomConfig.sink?.enabled &&
      currentRoomConfig.sink?.type === 'single'
    )
      values.push('sink')
    if (
      currentRoomConfig &&
      currentRoomConfig.sink?.enabled &&
      currentRoomConfig.sink?.type === 'double'
    )
      values.push('doublesink')

    return values
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const currentRoomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

    if (!currentRoomConfig) return // Update simple toggles
    ;['shower', 'bathtub', 'toilet'].forEach((item) => {
      currentRoomConfig[item].enabled = v.includes(item)
    })

    // Handle sink configuration
    const hasSink = v.includes('sink')
    const hasDoubleSink = v.includes('doublesink')

    currentRoomConfig.sink.enabled = hasSink || hasDoubleSink

    if (currentRoomConfig.sink.enabled) {
      if (hasSink && hasDoubleSink) {
        currentRoomConfig.sink.type =
          v.indexOf('doublesink') > v.indexOf('sink') ? 'double' : 'single'
      } else {
        currentRoomConfig.sink.type = hasDoubleSink ? 'double' : 'single'
      }
    }
  }
})

const extras = computed(() => {
  const values = []
  const currentRoom = model.value.services.baths.change.currentRoom
  const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]

  if (roomConfig.shower.enabled) {
    values.push({
      label: 'Duschtrennwand',
      image: PartitionImage,
      value: 'compartment'
    })
  }

  /*  if (roomConfig.sink.enabled) {
    values.push({
      label: 'Waschtisch-unterschrank',
      image: CabinetImage,
      value: 'baseCabinet'
    })
  }*/

  values.push({
    label: 'Handtuchheizkörper',
    image: TowelHeatingImage,
    value: 'towel_heating'
  })

  values.push({
    label: 'Decke mit Einbaustrahler',
    image: SpotsImage,
    value: 'ceiling'
  })

  return values
})

const extrasValue = computed({
  get: () => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
    const values = []

    if (roomConfig.shower.modification.partitionNoDoor) values.push('partition')
    if (roomConfig.shower.modification.compartment) values.push('compartment')
    if (roomConfig.sink.baseCabinet) values.push('baseCabinet')

    if (
      model.value.services.baths.radiator.items.some(
        (item) => item.value === 'towel_radiator_compact'
      ) &&
      model.value.services.baths.radiator.rooms.includes(currentRoom)
    ) {
      values.push('towel_heating')
    }
    if (model.value.services.heating.underfloor_heating.enabled) values.push('floor_heating')
    if (model.value.services.baths.ceilings.ceilingSpots.rooms.includes(currentRoom))
      values.push('ceiling')

    return values
  },
  set: (v) => {
    const currentRoom = model.value.services.baths.change.currentRoom
    const roomConfig = model.value.services.baths.change.roomConfigs[currentRoom]
    roomConfig.shower.modification.partitionNoDoor = v.includes('partition')
    roomConfig.shower.modification.compartment = v.includes('compartment')
    roomConfig.sink.baseCabinet = v.includes('baseCabinet')

    // Handle towel heating
    if (v.includes('towel_heating')) {
      model.value.services.baths.radiator.enabled = true
      const existingItem = model.value.services.baths.radiator.items.find(
        (item) => item.value === 'towel_radiator_compact'
      )

      if (!existingItem) {
        model.value.services.baths.radiator.items.push({
          label: 'x',
          value: 'towel_radiator_compact',
          amount: 1,
          image: 'x'
        })
      }

      if (!model.value.services.baths.radiator.rooms.includes(currentRoom)) {
        model.value.services.baths.radiator.rooms.push(currentRoom)
      }

      // Set the amount to match the number of rooms
      if (existingItem) {
        existingItem.amount = model.value.services.baths.radiator.rooms.length
      }
    } else {
      model.value.services.baths.radiator.rooms = model.value.services.baths.radiator.rooms.filter(
        (room) => room !== currentRoom
      )

      if (model.value.services.baths.radiator.rooms.length === 0) {
        model.value.services.baths.radiator.enabled = false
        model.value.services.baths.radiator.items =
          model.value.services.baths.radiator.items.filter(
            (item) => item.value !== 'towel_radiator_compact'
          )
      } else {
        const existingItem = model.value.services.baths.radiator.items.find(
          (item) => item.value === 'towel_radiator_compact'
        )
        if (existingItem) {
          existingItem.amount = model.value.services.baths.radiator.rooms.length
        }
      }
    }

    // Handle underfloor heating
    model.value.services.heating.underfloor_heating.enabled = v.includes('floor_heating')

    // Handle ceiling spots
    if (v.includes('ceiling')) {
      model.value.services.baths.ceilings.enabled = true
      model.value.services.baths.ceilings.ceilingSpots.enabled = true
      if (!model.value.services.baths.ceilings.ceilingSpots.rooms.includes(currentRoom)) {
        model.value.services.baths.ceilings.ceilingSpots.rooms.push(currentRoom)
      }
    } else {
      model.value.services.baths.ceilings.ceilingSpots.rooms =
        model.value.services.baths.ceilings.ceilingSpots.rooms.filter(
          (room) => room !== currentRoom
        )
      if (model.value.services.baths.ceilings.ceilingSpots.rooms.length === 0) {
        model.value.services.baths.ceilings.enabled = false
        model.value.services.baths.ceilings.ceilingSpots.enabled = false
      }
    }
  }
})

const submitting = ref(false)
const submitted = ref(false)

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.baths.change.currentRoom = roomId
    }
  }
)

const roomConfig = useLocalStorage(
  'form_path_roomConfig',
  JSON.parse(JSON.stringify(model.value.services.baths.change.config))
)

const hasSelectedRooms = computed(() => {
  return bathCreator.value > 0
})

onMounted(() => {
  model.value.services.baths.change.currentRoom = ''
  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'bath',
        content_name: 'bath'
      }
    ]
  })
  track.tmViewItem('bath')

  model.value.setup = true
  model.value.step = 'services_baths_start'
  model.value.services.baths.enabled = true
  model.value.services.baths.change.enabled = true
})
</script>
<style lang="postcss">
.small-qualities {
  img {
    @apply hidden;
  }

  @media (max-width: 1024px) {
    label {
      @apply ml-0 w-full pr-0 text-center;
    }

    [data-section='icon'] {
      @apply hidden;
    }
  }
}
</style>
