<script setup>
import { useLocalStorage } from '@vueuse/core'
import { initials, registerWatcher, registerShortcuts, totalInitials } from '@/utils/calculators.js'

import Heatpump from '@/components/Singles/Heatpump.vue'
import Facade from '@/components/Singles/Facade.vue'
import Floor from '@/components/Singles/Floor.vue'
import Bath from '@/components/Singles/Bath.vue'
import Electric from '@/components/Singles/Electric.vue'
import Window from '@/components/Singles/Window.vue'
import Walls from '@/components/Singles/Walls.vue'

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'heatpump'
  }
})

const json = (i) => JSON.parse(JSON.stringify(i))

const formData = useLocalStorage('form_' + props.type, {
  ...json(initials),
  type: props.type
})

const totals = useLocalStorage('totals_' + props.type, json(totalInitials))

const reset = () => {}

registerWatcher(formData, totals)
registerShortcuts(formData, totals, reset)

const components = {
  heatpump: Heatpump,
  facade: Facade,
  floor: Floor,
  bath: Bath,
  electric: Electric,
  window: Window,
  wallsCeilings: Walls
}
</script>

<template>
  <component :is="components[type]" v-model="formData" :totals="totals" />
</template>
