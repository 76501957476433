export const extendModelByParams = (model: any) => {
  if (typeof localStorage === 'undefined') {
    return model
  }

  const gclid = localStorage.getItem('fast_gclid')
  const fbclid = localStorage.getItem('fast_fbclid')
  const adcell = localStorage.getItem('fast_adcell')
  const utm = localStorage.getItem('fast_utm')

  if (gclid) {
    model.contact.gclid = gclid
  }

  if (fbclid) {
    model.contact.fbclid = fbclid
  }

  if (adcell) {
    model.contact.adcellid = adcell
  }

  if (utm) {
    model.utm = JSON.parse(utm)
  }

  return model
}
