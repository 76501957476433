<template>
  <section id="floor-wrapper">
    <form @submit.prevent="submitHandler" id="form">
      <div class="mx-auto mb-5 sm:mb-10">
        <p class="text-center text-3xl font-bold">{{ headlineText }}</p>
      </div>
      <div class="mx-auto mb-4 max-w-3xl">
        <ProgressBar :progress="currentProgress" class="" />
      </div>
      <div class="mx-auto max-w-3xl">
        <template v-if="model.step === 'services_floors_start'">
          <div class="mb-20">
            <Headline
              >In welchen Zimmern soll neuer Boden verlegt oder aufbereitet werden?</Headline
            >
            <div class="mx-auto mb-xl">
              <IconsOptionsSelection
                :options="roomTypes"
                cols="md:grid-cols-3"
                v-model="selectedRoomTypes"
                @update:modelValue="updateRooms"
                multiple
              >
                <template #option-slot="{ option, isActive }">
                  <div
                    v-if="isActive"
                    class="mt-3 flex w-full flex-row items-center justify-between"
                  >
                    <span class="hidden font-semibold sm:block">Anzahl:</span>
                    <span class="font-semibold sm:hidden">Anz.</span>
                    <CounterInput
                      v-model="roomAmounts[option.value]"
                      @update:modelValue="handleCounter($event, option.value)"
                    />
                  </div>
                </template>
              </IconsOptionsSelection>
            </div>
            <SingleNavigation
              :step="getNextStep"
              v-model="model"
              :disabled="!hasSelectedRooms"
              :fixed="true"
            />
          </div>
        </template>

        <template v-for="(roomId, index) in model.services.floors.roomIds" :key="roomId">
          <template v-if="model.step === `services_floors_size_${roomId}`">
            <Headline
              >Wie groß ist der folgende Raum:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <div class="my-20 space-y-16">
              <Range
                v-model="room(roomId).areas.floor"
                @update:modelValue="roomSize(roomId)"
                :max="100"
              />
            </div>
            <SingleNavigation
              :step="`services_floors_task_${roomId}`"
              :prev="
                index === 0
                  ? 'services_floors_start'
                  : `services_floors_old_${model.services.floors.roomIds[index - 1]}`
              "
              v-model="model"
              :disabled="!room(roomId).areas.floor"
            />
          </template>

          <template v-if="model.step === `services_floors_task_${roomId}`">
            <Headline
              >Was soll gemacht werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection :options="types" cols="md:grid-cols-2" v-model="roomTask" />
            <SingleNavigation
              v-if="isChange(roomId)"
              :step="`services_floors_type_${roomId}`"
              :prev="`services_floors_size_${roomId}`"
              v-model="model"
              :disabled="!roomTask"
              :fixed="true"
            />
            <SingleNavigation
              v-else-if="isRecycleWoodenFloor(roomId)"
              :step="`services_floors_boards_${roomId}`"
              :prev="`services_floors_size_${roomId}`"
              v-model="model"
              :disabled="!roomTask"
              :fixed="true"
            />
            <SingleNavigation
              v-else
              :step="`services_floors_boards_${roomId}`"
              :prev="`services_floors_size_${roomId}`"
              v-model="model"
              :disabled="!roomTask"
              :fixed="true"
            />
          </template>

          <template v-if="model.step === `services_floors_type_${roomId}`">
            <div class="mb-20">
              <Headline
                >Welchen Boden wünschen Sie sich:
                <span class="text-fancy">{{
                  getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
                }}</span
                >?</Headline
              >
              <IconsOptionsSelection :options="floors" v-model="floorType" />
              <SingleNavigation
                :step="`services_floors_quality_${roomId}`"
                :prev="`services_floors_task_${roomId}`"
                v-model="model"
                :disabled="!model.services.floors.change.roomConfigs[roomId].type"
                :fixed="true"
              />
            </div>
          </template>

          <template v-if="model.step === `services_floors_quality_${roomId}`">
            <Headline
              >In welcher Qualität wünschen Sie sich Ihren neuen Boden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection :options="qualities" v-model="floorChangeQuality" />
            <SingleNavigation
              :step="`services_floors_boards_${roomId}`"
              :prev="`services_floors_type_${roomId}`"
              v-model="model"
              :disabled="!model.services.floors.change.quality"
              :fixed="true"
            />
          </template>

          <template v-if="model.step === `services_floors_boards_${roomId}`">
            <Headline
              >Sollen die Fussleisten auch erneuert werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="yesNo"
              cols="md:grid-cols-2"
              v-model="selectedSkirtingBoardsComputed"
            />
            <SingleNavigation
              v-if="selectedSkirtingBoardsComputed && !isChange(roomId)"
              :step="`services_floors_perimeter_${roomId}`"
              :prev="`services_floors_task_${roomId}`"
              v-model="model"
              :fixed="true"
            />
            <SingleNavigation
              v-else-if="!selectedSkirtingBoardsComputed && !isChange(roomId)"
              :step="
                index === model.services.floors.roomIds.length - 1
                  ? 'contactForm'
                  : `services_floors_size_${model.services.floors.roomIds[index + 1]}`
              "
              :prev="`services_floors_task_${roomId}`"
              v-model="model"
              :fixed="true"
              :disabled="selectedSkirtingBoardsComputed === null"
            />
            <SingleNavigation
              v-else-if="selectedSkirtingBoardsComputed && isChange(roomId)"
              :step="`services_floors_perimeter_${roomId}`"
              :prev="`services_floors_quality_${roomId}`"
              v-model="model"
              :fixed="true"
            />
            <SingleNavigation
              v-else-if="!selectedSkirtingBoardsComputed && isChange(roomId)"
              :step="`services_floors_old_${roomId}`"
              :prev="`services_floors_quality_${roomId}`"
              v-model="model"
              :fixed="true"
              :disabled="selectedSkirtingBoardsComputed === null"
            />
          </template>

          <template v-if="model.step === `services_floors_perimeter_${roomId}`">
            <Headline
              >Wie viel Meter Fussleiste benötigen Sie für den folgenden Raum:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <div class="my-20 space-y-16">
              <Range
                v-model="room(roomId).areas.perimeter"
                :max="80"
                :formatter="(perimeter) => `${perimeter} m`"
              />
            </div>
            <SingleNavigation
              :step="`services_floors_boards_quality_${roomId}`"
              :prev="`services_floors_boards_${roomId}`"
              v-model="model"
              :disabled="!room(roomId).areas.floor"
              :fixed="true"
            />
          </template>

          <template v-if="model.step === `services_floors_boards_quality_${roomId}`">
            <Headline
              >In welcher Qualität wünschen Sie sich die neuen Fussleisten:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="qualities"
              v-model="model.services.floors.newSkirtingBoards.quality"
            />
            <SingleNavigation
              :step="`services_floors_old_${roomId}`"
              :prev="`services_floors_perimeter_${roomId}`"
              v-model="model"
              :disabled="!model.services.floors.newSkirtingBoards.quality"
              :fixed="true"
            />
          </template>

          <template v-if="model.step === `services_floors_old_${roomId}`">
            <Headline
              >Soll alter Bodenbelag entfernt werden:
              <span class="text-fancy">{{
                getFormattedRoomName(room(roomId)?.name, getRoomSortedIndex(room(roomId)))
              }}</span
              >?</Headline
            >
            <IconsOptionsSelection
              :options="yesNo"
              cols="md:grid-cols-2"
              v-model="removeOldFloorComputed"
            />
            <SingleNavigation
              v-if="selectedSkirtingBoardsComputed"
              :step="
                index === model.services.floors.roomIds.length - 1
                  ? 'contactForm'
                  : `services_floors_size_${model.services.floors.roomIds[index + 1]}`
              "
              :prev="`services_floors_boards_quality_${roomId}`"
              v-model="model"
              :fixed="true"
              :disabled="removeOldFloorComputed === null"
            />
            <SingleNavigation
              v-else-if="!selectedSkirtingBoardsComputed"
              :step="
                index === model.services.floors.roomIds.length - 1
                  ? 'contactForm'
                  : `services_floors_size_${model.services.floors.roomIds[index + 1]}`
              "
              :prev="`services_floors_boards_${roomId}`"
              v-model="model"
              :fixed="true"
              :disabled="removeOldFloorComputed === null"
            />
          </template>
        </template>
      </div>
      <NoTotalMessage
        :model="model"
        :totals="totals"
        v-if="model.step === 'contactForm' && !initIsCurrentStepInTotals"
        :isCurrentStepInTotals="initIsCurrentStepInTotals"
      />

      <ContactPanel
        v-else
        :model-value="modelValue"
        :totals="totals"
        :submitted="submitted"
        :loading="loading"
        :response="responseObject"
        :submitting="submitting"
        v-slot="{ totals, goBack }"
        :single="true"
        v-if="
          (model.step === 'contact' ||
            model.step === 'contractor' ||
            model.step === 'contactForm') &&
          initIsCurrentStepInTotals
        "
      />
    </form>
  </section>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import track from '@/utils/tracking.js'
import Headline from '@/components/Structure/Singles/Headline.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import SingleNavigation from '@/components/Structure/Singles/SingleNavigation.vue'
import Range from '@/components/Inputs/Singles/Range.vue'
import { extendModelByParams } from '@/utils/gclid'
import ProgressBar from '@/components/Elements/ProgressBar.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'
import { floors, qualities, roomTypes, types, yesNo } from '@/data/options/v2/floor.js'
import ContactPanel from '@/components/Structure/Singles/ContactPanel.vue'
import {
  getRoom,
  getRoomCount,
  getRoomNameWithIndex,
  getSortedIndex,
  handleCounterUpdate,
  updateRoomSize,
  updateSelectedRooms
} from '@/utils/roomManagementUtils.js'
import { isCurrentStepInTotals } from '@/utils/steps.js'
import NoTotalMessage from '@/components/Structure/Singles/NoTotalMessage.vue'

const API_URL = import.meta.env.VITE_API_URL

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  headlineText: String
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentProgress = computed(() => {
  const baseSteps = ['services_floors_start', 'contactForm']

  const roomSteps = [
    'services_floors_size',
    'services_floors_task',
    'services_floors_type',
    'services_floors_quality',
    'services_floors_boards',
    'services_floors_perimeter',
    'services_floors_boards_quality',
    'services_floors_old'
  ]

  // Generate steps for each room
  const steps = baseSteps.concat(
    ...selectedRoomIds.value.flatMap((roomId) => roomSteps.map((step) => `${step}_${roomId}`))
  )

  // Find the current step index
  const currentStepIndex = steps.findIndex((step) => model.value.step === step)

  if (model.value.step === 'contactForm') {
    return 100
  }

  if (currentStepIndex === -1) return 0

  // Calculate progress percentage
  return (currentStepIndex / (steps.length - 1)) * 100
})

const selectedRoomIds = computed(() => {
  return model.value.services.floors.roomIds || []
})

const roomTask = computed({
  get: () => {
    const currentRoom = model.value.services.floors.change.currentRoom
    if (model.value.services.floors.change.roomConfigs[currentRoom]) {
      return 'change'
    }
    if (model.value.services.floors.recycleWoodenFloor.rooms.includes(currentRoom)) {
      return 'recycleWoodenFloor'
    }
    return null
  },
  set: (v) => {
    const currentRoom = model.value.services.floors.change.currentRoom
    if (v === 'change') {
      model.value.services.floors.change.enabled = true
      if (model.value.services.floors.recycleWoodenFloor.rooms.includes(currentRoom)) {
        model.value.services.floors.recycleWoodenFloor.rooms =
          model.value.services.floors.recycleWoodenFloor.rooms.filter((id) => id !== currentRoom)
      }
      if (!model.value.services.floors.change.roomConfigs[currentRoom]) {
        model.value.services.floors.change.roomConfigs[currentRoom] = roomConfig.value
      }
      roomConfig.value = {
        type: ''
      }
    } else if (v === 'recycleWoodenFloor') {
      model.value.services.floors.recycleWoodenFloor.enabled = true
      if (model.value.services.floors.change.roomConfigs[currentRoom]) {
        delete model.value.services.floors.change.roomConfigs[currentRoom]
      }
      if (!model.value.services.floors.recycleWoodenFloor.rooms.includes(currentRoom)) {
        model.value.services.floors.recycleWoodenFloor.rooms.push(currentRoom)
      }
    }
  }
})

const getFormattedRoomName = (label, overallIndex) => {
  return getRoomNameWithIndex(label, overallIndex, model, 'services.floors', roomTypes)
}

const getRoomSortedIndex = (room) => {
  return getSortedIndex(room, model, 'services.floors')
}

const updateRooms = (newTypes) => {
  updateSelectedRooms(model, 'services.floors', newTypes, roomTypes)
  // Update room amounts
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = roomCount(type.value)
  })
}

const handleCounter = (value, roomType) => {
  handleCounterUpdate(model, 'services.floors', roomAmounts, roomType, value, roomTypes)
}

const roomCount = (roomType) => {
  return getRoomCount(model, 'services.floors', roomType)
}

const room = (roomId) => {
  return getRoom(model, roomId)
}

const roomSize = (roomId) => {
  updateRoomSize(model, roomId)
}

const floorType = computed({
  get: () => {
    const currentRoomConfig =
      model.value.services.floors.change.roomConfigs[model.value.services.floors.change.currentRoom]
    return currentRoomConfig ? currentRoomConfig.type : ''
  },
  set: (v) => {
    model.value.services.floors.change.roomConfigs[
      model.value.services.floors.change.currentRoom
    ].type = v
  }
})

const isChange = (roomId) => {
  if (model.value.services.floors.change.roomConfigs[roomId]) {
    return 'change'
  }
}

const isRecycleWoodenFloor = (roomId) => {
  if (model.value.services.floors.recycleWoodenFloor.rooms.includes(roomId)) {
    return 'recycleWoodenFloor'
  }
}

const floorChangeQuality = computed({
  get: () => {
    return model.value.services.floors.change.quality
  },
  set: (v) => {
    model.value.services.floors.change.quality = v
  }
})

const selectedSkirtingBoardsComputed = computed({
  get: () => {
    if (model.value.services.floors.newSkirtingBoards.enabled === null) {
      return null
    }
    return !!model.value.services.floors.newSkirtingBoards.rooms.includes(
      model.value.services.floors.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.floors.newSkirtingBoards.enabled = true
      if (
        !model.value.services.floors.newSkirtingBoards.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.newSkirtingBoards.rooms.push(
          model.value.services.floors.change.currentRoom
        )
        model.value.services.floors.newSkirtingBoards.rooms.push(
          model.value.services.floors.change.currentRoom
        )
      }
    } else {
      model.value.services.floors.newSkirtingBoards.enabled = false
      if (
        model.value.services.floors.newSkirtingBoards.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.newSkirtingBoards.rooms =
          model.value.services.floors.newSkirtingBoards.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
        model.value.services.floors.newSkirtingBoards.rooms =
          model.value.services.floors.newSkirtingBoards.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
      }
    }
  }
})

const removeOldFloorComputed = computed({
  get: () => {
    if (model.value.services.floors.removeOld.enabled === null) {
      return null
    }

    return !!model.value.services.floors.removeOld.rooms.includes(
      model.value.services.floors.change.currentRoom
    )
  },
  set: (v) => {
    if (v) {
      model.value.services.floors.removeOld.enabled = true
      if (
        !model.value.services.floors.removeOld.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.removeOld.rooms.push(
          model.value.services.floors.change.currentRoom
        )
        model.value.services.floors.removeOld.rooms.push(
          model.value.services.floors.change.currentRoom
        )
      }
    } else {
      model.value.services.floors.removeOld.enabled = false
      if (
        model.value.services.floors.removeOld.rooms.includes(
          model.value.services.floors.change.currentRoom
        )
      ) {
        model.value.services.floors.removeOld.rooms =
          model.value.services.floors.removeOld.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
        model.value.services.floors.removeOld.rooms =
          model.value.services.floors.removeOld.rooms.filter(
            (id) => id !== model.value.services.floors.change.currentRoom
          )
      }
    }
  }
})

const roomAmounts = ref({
  living_room: 0,
  kitchen: 0,
  bathroom: 0,
  utility_room: 0,
  cellar: 0,
  bedroom: 0
})

const hasSelectedRooms = computed(() => {
  return selectedRoomIds.value.length > 0
})

const getNextStep = computed(() => {
  if (selectedRoomIds.value.length > 0) {
    return `services_floors_size_${selectedRoomIds.value[0]}`
  }
  return 'services_floors_start'
})

const selectedRoomTypes = computed({
  get: () => {
    return Array.from(
      new Set(
        selectedRoomIds.value
          .map((id) => model.value.rooms.find((r) => r.id === id)?.type)
          .filter(Boolean)
      )
    )
  },
  set: (newTypes) => {
    updateRooms(newTypes)
  }
})

const responseObject = ref()
const loading = ref(false)
const submitting = ref(false)
const submitted = ref(false)

const submitHandler = async (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  loading.value = true
  submitting.value = true

  setTimeout(() => {
    loading.value = false
    submitted.value = true
  }, 3000)

  const formModel = extendModelByParams(model.value)

  try {
    const response = await fetch(API_URL + '/api/forms/sc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(formModel)
    })
    const { data } = await response.json()
    responseObject.value = {
      data,
      message: 'Form successfully submitted!',
      error: false
    }

    submitted.value = true
    track.fast('Lead_Floor', data)
    track.yandex(97558574, 'reachGoal', 'form-click')
    track.tiktok(
      'SubmitForm',
      {
        contents: [
          {
            content_id: 'floor',
            content_name: 'floor'
          }
        ],
        value: data.volume / 100,
        currency: 'EUR'
      },
      {
        email: model.value.contact.email,
        external_id: data.id
      }
    )
    track.tmPurchase('floor', data.volume / 100, 'EUR', data.id, model.value.contact)

    return data
  } catch (error) {
    responseObject.value = {
      error: true,
      message: 'An error occurred'
    }
    throw error // Re-throw the error for the caller to handle if needed
  } finally {
    submitting.value = false
  }
}

const roomConfig = ref({
  type: ''
})

watch(roomAmounts, (newAmounts, oldAmounts) => {
  Object.keys(newAmounts).forEach((roomType) => {
    updateRooms(roomType)
  })
})

watch(
  () => model.value.rooms,
  (newRooms) => {
    // Remove extra rooms from roomConfigs
    Object.keys(model.value.services.floors.change.roomConfigs).forEach((roomId) => {
      if (!newRooms.some((room) => room.id === roomId)) {
        delete model.value.services.floors.change.roomConfigs[roomId]
      }
    })
  },
  { deep: true }
)

watch(
  () => model.value.step,
  (v) => {
    const roomId = v.split('_').pop()
    if (roomId) {
      model.value.services.floors.change.currentRoom = roomId
    }
  }
)

const initIsCurrentStepInTotals = computed(() => {
  if (model.value.step === 'contactForm') {
    return isCurrentStepInTotals(model, props.totals, 'floors')
  }
})

watch(
  () => model.value.step,
  () => {
    if (model.value.step === 'contactForm') {
      initIsCurrentStepInTotals.value = isCurrentStepInTotals(model, props.totals, 'floors')
      console.log('initIsCurrentStepInTotals', initIsCurrentStepInTotals.value)
    }
  }
)

onMounted(() => {
  model.value.base.type = 'house'
  if (!model.value.services.floors.roomIds) {
    model.value.services.floors.roomIds = []
  }

  // Initialize room amounts based on selected room IDs
  roomTypes.forEach((type) => {
    roomAmounts.value[type.value] = getRoomCount(model, 'services.floors', type.value)
  })

  if (model.value.rooms.length > 0) {
    model.value.step = `services_floors_task_${model.value.rooms[0].id}`
  } else {
    model.value.step = 'services_floors_start'
  }

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'floors',
        content_name: 'floors'
      }
    ]
  })
  track.tmViewItem('floors')
  model.value.setup = true
  model.value.step = 'services_floors_start'
  model.value.services.floors.enabled = true
})
</script>
