import { createApp } from 'vue'
import { createPinia } from 'pinia'
import mitt from 'mitt'

import './assets/main.css'
import 'floating-vue/dist/style.css'
// import * as Sentry from '@sentry/vue'

import { vTooltip } from 'floating-vue'
import i18n from '@/i18n'

// Function to bootstrap and mount the Vue.js application
export function bootstrap(App: any, target: any, attributes: any) {
  // Create a new Vue application instance
  // second argument is object of attributes that will be
  // converted to properites in App.vue
  const emitter = mitt()

  const app = createApp(App, attributes)

  app.use(i18n)
  app.use(createPinia())
  app.directive('tooltip', vTooltip)
  app.directive('focus', {
    mounted: (el) => el.focus()
  })
  app.config.globalProperties.emitter = emitter

  // Mount the Vue application onto the specified target element
  app.mount(target)
}
