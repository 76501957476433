<template>
  <div id="old-form" class="relative">
    <div class="mx-auto max-w-xl">
      <div class="rounded-3xl">
        <template v-if="loading">
          <div class="mt-60 flex items-center justify-center">
            <LoadingCircle />
          </div>
        </template>
        <template v-else-if="!loading">
          <div
            v-if="currentStep === 'costs'"
            class="mt-5 grid grid-cols-1 items-center justify-center gap-5"
          >
            <div class="flex flex-col items-center">
              <h2 class="mb-10 text-center text-2xl" v-if="!providerFound">
                Wir suchen die besten Handwerker in ihrer Region:
              </h2>
              <h2 class="mb-10 text-center text-2xl" v-else>Handwerker gefunden!</h2>
              <div class="relative h-[250px] w-[250px]">
                <div
                  class="absolute inset-0 flex items-center justify-center rounded-full border-4 border-primary p-2"
                >
                  <Loop class="animate-search-around text-[150px]" v-if="!providerFound" />
                  <Yes class="text-[170px]" v-else @click="nextStep" />
                </div>
              </div>
              <div
                class="mt-10 flex flex-col items-center justify-between gap-2 text-xl font-semibold"
                v-if="providerFound"
              >
                <p>Unverbindliche Kostenschätzung:</p>
                <AnimatedNumber :model-value="totals.total.value" class="" />
              </div>
              <div
                class="mt-8 flex justify-between gap-5 rounded-xl bg-primary p-2 text-2xl font-semibold text-action"
                v-if="providerFound"
              >
                <button
                  @click="nextStep"
                  class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 text-xl text-action"
                >
                  <span>Kostenlose Angebote erhalten</span>
                </button>
              </div>
            </div>
          </div>

          <fieldset v-if="currentStep === 'contractor'" class="flex flex-col gap-lg">
            <div class="flex flex-col items-center justify-center">
              <h3 class="text-center text-2xl font-bold dark:text-white md:text-3xl">
                Möchten Sie eine Sanierung mit einem Generalunternehmen?
              </h3>
              <InfoPanelIcon
                height="h-9 mt-2 md:h-10"
                :content="contractor"
                title="Generalunternehmen"
              >
                <slot name="info" />
              </InfoPanelIcon>
            </div>
            <IconsOptionsSelection
              v-model="model.services.contractor.enabled"
              :options="yesNo"
              cols="md:grid-cols-2"
            />

            <div class="flex flex-row items-center justify-center text-center">
              <p>
                <b
                  >Bei einer Sanierung mit 4 oder mehr unterschiedlichen Gewerken empfehlen wir
                  Ihnen die Zusammenarbeit mit einem Generalunternehmen</b
                >. Die Sanierungskosten erhöhen sich dadurch um ca. 15%.
              </p>
            </div>
            <button
              :disabled="model.services.contractor.enabled === null"
              @click="nextStep"
              class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
            >
              <span>Weiter </span>
            </button>
          </fieldset>

          <fieldset v-if="currentStep === 'zip'" class="flex flex-col gap-5">
            <div
              class="flex flex-col items-center justify-between gap-2 text-xl font-semibold sm:text-2xl"
            >
              <p class="text-center">Unverbindliche Kostenschätzung:</p>
              <AnimatedNumber
                :model-value="totals.total.value"
                class="text-2xl font-bold underline sm:text-3xl"
              />
            </div>

            <p class="text-center text-xl font-semibold sm:text-2xl">
              Sichern Sie jetzt Ihre Leistungsübersicht!
            </p>

            <input
              type="text"
              id="address"
              v-model="model.base.address"
              placeholder="Straße und Hausnr. des Objektes"
              class="input input-final"
            />
            <input
              type="text"
              id="city"
              v-model="model.base.city"
              placeholder="Ort des Objektes"
              class="input input-final"
            />
            <input
              type="text"
              id="postal_code"
              v-model="postalCode"
              placeholder="Postleitzahl des Objektes"
              class="input input-final"
              @keydown.enter="handleEnterKey"
            />

            <button
              id="zip-next"
              @click="nextStep"
              class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
              :disabled="!isAddressValid"
            >
              <span>Weiter </span>
            </button>
            <button
              v-if="contractorStep"
              @click="previousStep('contractor')"
              class="flex w-full flex-row items-center justify-center gap-x-3 px-3 underline"
            >
              <span>zurück </span>
            </button>
          </fieldset>

          <fieldset
            v-if="currentStep === 'name' && !submitted && !submitting"
            class="grid grid-cols-1 gap-4"
          >
            <div>
              <p class="text-center text-xl font-bold">
                Holen Sie sich jetzt Ihre persönliche und detaillierte Leistungsübersicht!
              </p>
            </div>

            <input
              type="text"
              v-model="model.contact.fullName"
              id="fullname"
              placeholder="Vollständiger Name (Vorname und Nachname)"
              class="input input-final"
            />
            <input
              type="email"
              id="email"
              v-model="model.contact.email"
              placeholder="Ihre E-Mail Adresse"
              class="input input-final"
            />

            <p
              class="hidden cursor-pointer text-center text-primary underline sm:block"
              @click="showModal = true"
            >
              Muster einer Leistungsübersicht anzeigen!
            </p>
            <a
              class="cursor-pointer items-center text-center text-primary underline sm:hidden"
              href="/pdf/b2c.pdf"
              target="_blank"
              title="Read PDF"
              >Muster einer Leistungsübersicht anzeigen!</a
            >
            <Modal
              :is-open="showModal"
              title="Muster Leistungsverzeichnis"
              @close="showModal = false"
            >
              <div>
                <embed src="/pdf/b2c.pdf" type="application/pdf" width="100%" height="600px" />
              </div>
            </Modal>
            <div class="relative -my-5 flex w-full items-end justify-center">
              <div class="relative">
                <div class="relative -right-12 top-8 text-xl text-gray-500">
                  Statt: <span class="text-gray-500 line-through">149,90€</span>
                </div>
                <div class="relative -left-12 -top-3 z-10 text-5xl font-bold">
                  <span class="text-primary">79</span
                  ><span class="relative -top-5 text-xl text-primary">90 €</span>
                  <img
                    alt=""
                    src="/images/Strich.svg"
                    class="absolute left-14 top-0 z-50 h-32 w-auto -translate-x-1/2"
                  />
                </div>
              </div>
            </div>
            <button
              id="submit"
              type="submit"
              name="submit"
              @click="isProtected.value = false"
              class="btn btn--primary mt-10 flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
              :disabled="submitting || isFormInvalid()"
            >
              <Loading v-if="submitting" class="my-1" />
              <span v-else>Jetzt kaufen</span>
            </button>
            <div class="flex w-full items-center justify-center px-3 py-2"></div>
            <a href="#" @click.prevent="previousStep('zip')" class="underline"> zurück </a>
          </fieldset>
        </template>
      </div>
    </div>

    <div class="mt-5 rounded-b-3xl bg-white sm:p-10" v-if="!isProtected">
      <!--
      <slot :totals="totals" :model="model" :goBack="goBack">
        <h2 class="mb-10 text-2xl">Unsere Kostenschätzung</h2>
        <div class="mb-10">
          <div v-for="i in totals.services" class="mb-5 border-b-2 border-primary pb-5">
            <div class="mb-1 flex justify-between">
              <h3 class="text-lg">{{ i.name }}</h3>
              <p class="text-lg font-bold">
                <AnimatedNumber :model-value="i.total.value" />
              </p>
            </div>
          </div>
          <div class="flex items-end justify-between">
            <div class="">
              <button type="button" class="btn btn--primary" @click="goBack">Zum Anfang</button>
            </div>
            <p class="text-4xl font-bold">
              <AnimatedNumber :model-value="totals.total.value" />
            </p>
          </div>
        </div>
      </slot>
      -->
    </div>
  </div>
</template>

<script setup>
import Loading from '@/components/Elements/Loading.vue'
import track from '@/utils/tracking'
import { defineProps, defineEmits, computed, onMounted, ref, watch, nextTick } from 'vue'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import AnimatedNumber from '@/components/Inputs/AnimatedNumber.vue'
import Loop from '@/components/Icons/Loop.vue'
import Yes from '@/components/Icons/Yes.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { yesNo } from '@/data/options/v2/baths.js'
import InfoPanelIcon from '@/components/Elements/InfoPanelIcon.vue'
import { contractor } from '@/data/infos/contractor.js'
import LoadingCircle from '@/components/Elements/LoadingCircle.vue'
import ElementModal from '@/components/Elements/ElementModal.vue'
import Modal from '@/components/Elements/Modal.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Object,
  totals: Object,
  submitted: Boolean,
  submitting: Boolean,
  response: Object,
  options: {
    type: Array,
    required: false
  },
  loading: Boolean
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const showModal = ref(false)

const enabledServices = computed(() => {
  return Object.keys(model.value.services).filter((key) => model.value.services[key].enabled)
})

const isProtected = ref(true)
const steps = ['contractor', 'zip', 'name']
const currentStep = ref('')

const isFormInvalid = () => {
  return (
    !model.value.base.postal_code ||
    !model.value.contact.fullName ||
    !model.value.contact.email ||
    !model.value.base.address ||
    !model.value.base.city
  )
}

const isAddressValid = computed(() => {
  return (
    model.value.base.postal_code &&
    model.value.base.postal_code.toString().length === 5 &&
    model.value.base.city &&
    model.value.base.address
  )
})
// watch fullName and split it into firstname and lastname
watch(
  () => model.value.contact.fullName,
  (newVal) => {
    const [firstName, ...lastNameParts] = newVal.split(' ')
    model.value.contact.firstname = firstName || ''
    model.value.contact.lastname = lastNameParts.join(' ') || 'x'
  },
  { deep: true }
)

const nextStep = () => {
  const currentIndex = steps.indexOf(currentStep.value)
  if (currentIndex < steps.length - 1) {
    currentStep.value = steps[currentIndex + 1]
  }
}
const handleEnterKey = (e) => {
  if (e.key === 'Enter') {
    nextStep()
  }
}

const previousStep = (step) => {
  if (step) {
    currentStep.value = step
    return
  }
  const currentIndex = steps.indexOf(currentStep.value)
  if (currentIndex > 0) {
    currentStep.value = steps[currentIndex - 1]
  }
}

const postalCode = computed({
  get: () => model.value.base.postal_code,
  set: (v) => {
    model.value.base.postal_code = v
    model.value.contact.postal_code = v
  }
})

const providerFound = ref(false)

watch(currentStep, (newStep) => {
  if (newStep === 'costs') {
    providerFound.value = false // Reset to false when entering 'costs' step
    setTimeout(() => {
      providerFound.value = true
    }, 3000) // 3000 milliseconds = 3 seconds
  }
})

const redirectToRoot = () => {
  window.location.href = '/'
}

const contractorStep = ref(false)

watch(
  () => model.value.step,
  (newValue) => {
    if (enabledServices.value.length >= 4) {
      currentStep.value = 'contractor'
      contractorStep.value = true
    } else {
      currentStep.value = 'zip'
      contractorStep.value = false
    }
  }
)

watch(
  () => enabledServices.value,
  (newValue, oldValue) => {
    if (!newValue.length) {
      model.value.step = 'services'
    }
  }
)

onMounted(() => {
  if (enabledServices.value.length >= 4) {
    currentStep.value = 'contractor'
    contractorStep.value = true
  } else {
    currentStep.value = 'zip'
    contractorStep.value = false
  }
  model.value.contact.postal_code = model.value.base.postal_code
  track.yandex(97558944, 'reachGoal', 'contactPage')
  track.tmBegin(model.value.type, props.totals.total.value)

  /*  if (typeof reviewsBadgeRibbon !== 'undefined' && !props.submitted)
      reviewsBadgeRibbon('badge-ribbon', {
        store: 'www.moreathome.de',
        size: 'small',
        mono: true
      })*/
})
</script>
